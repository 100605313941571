import React from 'react'
import { Flex, Image, Switch } from '@chakra-ui/react'
import { PublicRoutes } from '../routes/PublicRoutes'
import { Navigate, Outlet, Route } from 'react-router-dom'
import { isAuth } from '../services/auth'
const Splash = () => {
    if (isAuth()) {
        return <Navigate to={'/dashboard'} replace />
    }
    return (
        <Flex height={'100vh'}>
            <Flex
                height={'100%'}
                width={'50%'}
                justify={'center'}
                alignItems={'center'}
            >
                <Outlet />
            </Flex>
            <Flex width={'50%'} height={'100vh'}>
                <Image
                    width={'100%'}
                    src={'/images-static/login-bg.png'}
                    style={{ borderRadius: '0 0 0 200px' }}
                />
            </Flex>
        </Flex>
    )
}

export default Splash
