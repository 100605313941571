import {
    Box,
    Button,
    Checkbox,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Input,
    InputGroup,
    InputRightElement,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Select,
    useDisclosure,
} from '@chakra-ui/react'
import React, { useRef } from 'react'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { RootState } from '../../store'
import { cancelAction, createUserAction } from './userFormSlice'
import { ImEye, ImEyeBlocked } from 'react-icons/im'
import { SubmitHandler, useForm } from 'react-hook-form'
import {
    AutoComplete,
    AutoCompleteInput,
    AutoCompleteItem,
    AutoCompleteList,
} from '@choc-ui/chakra-autocomplete'
import { isOwner, USER_ROLES } from '../../services/account'
import { IUser } from '../../repository/users'
import { fetchAllUsers } from '../Users/usersSlice'

export default () => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const finalRef = useRef(null)
    const dispatch = useAppDispatch()
    const userForm = useAppSelector((state: RootState) => state.userForm)
    const locations = useAppSelector((state: RootState) => state.locations)

    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm<IUser>({ defaultValues: { ...userForm.data } })
    const onSubmit: SubmitHandler<IUser> = async (data) => {
        await dispatch(createUserAction({ ...data, role: +data.role }))
        closeModal()
    }

    const closeModal = () => {
        dispatch(cancelAction())
        dispatch(fetchAllUsers())
        onClose()
    }
    const selectedRole = watch('role')

    const USER_ROLES_OPTIONS = [
        ...(isOwner()
            ? [
                  {
                      name: 'OWNER',
                      id: 0,
                  },
              ]
            : []),
        {
            name: 'ADMIN',
            id: 1,
        },
        { name: 'USER', id: 2 },
    ]

    return (
        <>
            {userForm.isVisible && (
                <Modal
                    finalFocusRef={finalRef}
                    isOpen={userForm.isVisible}
                    onClose={closeModal}
                >
                    <ModalOverlay />
                    <ModalContent>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <ModalHeader>Create a user</ModalHeader>
                            <ModalCloseButton />
                            <ModalBody>
                                <InputGroup size="md">
                                    <Input
                                        type={'hidden'}
                                        {...register('userId')}
                                    />
                                </InputGroup>
                                <FormControl
                                    isInvalid={!!errors.email}
                                    mb={'30px'}
                                >
                                    <FormLabel>
                                        Email<span>*</span>
                                    </FormLabel>
                                    <InputGroup size="md">
                                        <Input
                                            pr="4.5rem"
                                            type={'email'}
                                            disabled={!!userForm.data.userId}
                                            placeholder="test@example.com"
                                            {...register('email', {
                                                required: true,
                                            })}
                                        />
                                    </InputGroup>
                                    {!!errors.email && (
                                        <FormErrorMessage>
                                            Email is required.
                                        </FormErrorMessage>
                                    )}
                                </FormControl>
                                <FormControl
                                    isInvalid={!!errors.displayName}
                                    mb={'30px'}
                                >
                                    <FormLabel>
                                        Name<span>*</span>
                                    </FormLabel>
                                    <InputGroup size="md">
                                        <Input
                                            pr="4.5rem"
                                            type={'text'}
                                            placeholder="Enter display name"
                                            {...register('displayName', {
                                                required: true,
                                            })}
                                        />
                                    </InputGroup>
                                    {!!errors.displayName && (
                                        <FormErrorMessage>
                                            Display name is required.
                                        </FormErrorMessage>
                                    )}
                                </FormControl>
                                <FormControl
                                    isInvalid={!!errors.phone}
                                    mb={'30px'}
                                >
                                    <FormLabel>
                                        Phone<span>*</span>
                                    </FormLabel>
                                    <InputGroup size="md">
                                        <Input
                                            pr="4.5rem"
                                            type={'text'}
                                            placeholder="Enter phone number"
                                            {...register('phone', {
                                                required: true,
                                            })}
                                        />
                                    </InputGroup>
                                    {!!errors.phone && (
                                        <FormErrorMessage>
                                            Phone is required.
                                        </FormErrorMessage>
                                    )}
                                </FormControl>
                                <FormControl
                                    isInvalid={!!errors.role}
                                    mb={'30px'}
                                >
                                    <FormLabel>
                                        Role<span>*</span>
                                    </FormLabel>
                                    <Select
                                        placeholder="Select option"
                                        {...register('role', {
                                            required: true,
                                        })}
                                    >
                                        {USER_ROLES_OPTIONS.map((role, cid) => (
                                            <option
                                                value={role.id}
                                                key={role.id}
                                            >
                                                {role.name}
                                            </option>
                                        ))}
                                    </Select>

                                    {!!errors.role && (
                                        <FormErrorMessage>
                                            Role is required.
                                        </FormErrorMessage>
                                    )}
                                </FormControl>
                                {selectedRole == USER_ROLES.ADMIN && (
                                    <FormControl
                                        isInvalid={!!errors.location}
                                        mb={'30px'}
                                    >
                                        <FormLabel>
                                            Location<span>*</span>
                                        </FormLabel>
                                        <Select
                                            placeholder="Select option"
                                            {...register('location', {
                                                required: true,
                                            })}
                                        >
                                            {(locations.data || []).map(
                                                (location, cid) => (
                                                    <option
                                                        value={
                                                            location.locationId
                                                        }
                                                        key={
                                                            location.locationId
                                                        }
                                                    >
                                                        {location.name}
                                                    </option>
                                                )
                                            )}
                                        </Select>

                                        {!!errors.role && (
                                            <FormErrorMessage>
                                                Role is required.
                                            </FormErrorMessage>
                                        )}
                                    </FormControl>
                                )}
                                <FormControl
                                    isInvalid={!!errors.active}
                                    mb={'30px'}
                                >
                                    <FormLabel>Active</FormLabel>
                                    <Checkbox
                                        {...register('active')}
                                        mr={'30px'}
                                    ></Checkbox>
                                    {!!errors.active && (
                                        <FormErrorMessage>
                                            Active is required.
                                        </FormErrorMessage>
                                    )}
                                </FormControl>
                            </ModalBody>

                            <ModalFooter>
                                <Button mr={3} onClick={closeModal}>
                                    Cancel
                                </Button>
                                <Button colorScheme="messenger" type={'submit'}>
                                    Save
                                </Button>
                            </ModalFooter>
                        </form>
                    </ModalContent>
                </Modal>
            )}
        </>
    )
}
