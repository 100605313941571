import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs'
import {
    Button,
    Divider,
    Flex,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Select,
    Spinner,
    Text,
    useDisclosure,
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { ILocation } from '../../repository/locations'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import CustomTable from '../../components/CustomTable/CustomTable'
import dayjs from 'dayjs'
import { BiTrash } from 'react-icons/all'
import { deleteOrderAction } from './StatisticsSlice'
import { useAppDispatch } from '../../hooks'

type FetchStatisticsFields = {
    locationId: string
    startDate: string
    endDate: string
    hidden: boolean
    searchQuery: string
}
export default ({
    statistics,
    locations,
    handleStatisticsFetch,
    loading,
}: {
    statistics: any
    locations: ILocation[]
    loading: boolean
    handleStatisticsFetch: Function
}) => {
    let today = dayjs()
    const dispatch = useAppDispatch()
    today = today
        .set('hour', 9)
        .set('minutes', 0)
        .set('second', 0)
        .set('milliseconds', 0)
    let tomorrow = today.add(1, 'day')
    tomorrow = tomorrow
        .set('hour', 3)
        .set('minutes', 0)
        .set('second', 0)
        .set('milliseconds', 0)
    const [startDate, setStartDate] = React.useState(today.toISOString())
    const [endDate, setEndDate] = React.useState(tomorrow.toISOString())
    const [hiddenClicks, setHiddenClicks] = React.useState(0)
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [idToDelete, setIdToDelete] = useState('')
    const {
        register,
        handleSubmit,
        watch,
        getValues,
        setValue,
        control,
        formState: { errors },
    } = useForm<FetchStatisticsFields>({
        defaultValues: {
            hidden: false,
            startDate: today.toISOString(),
            endDate: tomorrow.toISOString(),
        },
    })

    const cols = [
        { label: 'Product name', field: 'productName' },
        { label: 'Quantity', field: 'quantity' },
    ]
    const orderCols: any = [
        { label: 'Order Id', field: 'orderId' },
        { label: 'Name', field: 'orderName' },
        { label: 'Created at', field: 'createdAt' },
        { label: 'Order Items', field: 'orderItems' },
        { label: 'Order price', field: 'totalPrice' },
        { label: 'Order discount (%)', field: 'discount' },
        { label: 'Order discount (MKD)', field: 'orderDiscount' },
        { label: 'Fiscal receipt', field: 'fiscalReceipt' },
        {
            label: 'Actions',
            type: 'icons',
            icons: [
                {
                    show: true,
                    callbackMethod: (row: any) => {
                        setIdToDelete(row.orderId)
                        onOpen()
                    },
                    icon: <BiTrash />,
                },
            ],
        },
    ]
    const profitCols = [
        { label: 'Total revenue', field: 'totalRevenue' },
        { label: 'Discounted total', field: 'discountedTotal' },
        { label: 'Fiscal Total', field: 'fiscalTotal' },
        { label: 'Total Orders', field: 'totalOrders' },
        { label: 'Discounted orders', field: 'discountedOrders' },
        { label: 'Non Fiscal Orders', field: 'nonFiscalOrders' },
        { label: 'Fiscal Orders', field: 'fiscalOrders' },
    ]
    const onSubmit: SubmitHandler<FetchStatisticsFields> = (data) => {
        handleStatisticsFetch(
            data.locationId,
            data.startDate,
            data.endDate,
            data.searchQuery,
            data.hidden
        )
    }

    const handleChange = (dateChange: any, field: 'startDate' | 'endDate') => {
        const date = new Date(dateChange)

        const utcDateChange = Date.UTC(
            date.getUTCFullYear(),
            date.getUTCMonth(),
            date.getUTCDate(),
            date.getUTCHours(),
            date.getUTCMinutes(),
            date.getUTCSeconds()
        )
        setValue(field, new Date(utcDateChange).toISOString(), {
            shouldDirty: true,
        })
        field === 'startDate'
            ? setStartDate(new Date(utcDateChange).toISOString())
            : setEndDate(new Date(utcDateChange).toISOString())
    }
    const locationId = watch('locationId')
    return (
        <>
            <Breadcrumbs />
            <Divider my={30} />
            <Flex
                my={10}
                justifyContent={{ base: 'flex-start', lg: 'flex-end' }}
            >
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Flex direction={{ base: 'column', lg: 'row' }}>
                        <Flex
                            mr={3}
                            flexDirection={'column'}
                            justifyContent={'center'}
                            alignItems={'center'}
                            direction={{ base: 'column', lg: 'row' }}
                            mb={{ base: 2, lg: 0 }}
                        >
                            <Text
                                onClick={(e) => {
                                    setHiddenClicks(hiddenClicks + 1)
                                    if (hiddenClicks + 1 === 4) {
                                        setValue('hidden', true)
                                    }
                                }}
                            >
                                Please select the information
                            </Text>
                        </Flex>
                        <Flex direction={{ base: 'column', lg: 'row' }}>
                            <Select
                                placeholder="Select location"
                                mr={{ base: 0, lg: 5 }}
                                mb={{ base: 2, lg: 0 }}
                                {...register('locationId')}
                            >
                                {(locations || []).map((location, cid) => (
                                    <option
                                        value={location.locationId}
                                        key={location.locationId}
                                    >
                                        {location.name}
                                    </option>
                                ))}
                            </Select>
                            <Input
                                maxW={'300px'}
                                mr={{ base: 0, lg: 5 }}
                                mb={{ base: 2, lg: 0 }}
                                placeholder={'Search query'}
                                {...register('searchQuery')}
                            />
                            <Flex
                                mr={{ base: 0, lg: 5 }}
                                mb={{ base: 2, lg: 0 }}
                                maxW={'300px'}
                                w={'100%'}
                            >
                                <Controller
                                    name="startDate"
                                    control={control}
                                    defaultValue={startDate}
                                    render={() => (
                                        <DatePicker
                                            showTimeSelect
                                            selected={
                                                startDate
                                                    ? new Date(startDate)
                                                    : null
                                            }
                                            placeholderText="Select start date"
                                            onChange={(e) =>
                                                handleChange(e, 'startDate')
                                            }
                                        />
                                    )}
                                />
                            </Flex>
                            <Flex
                                mr={{ base: 0, lg: 5 }}
                                mb={{ base: 2, lg: 0 }}
                                maxW={'300px'}
                                w={'100%'}
                            >
                                <Controller
                                    name="endDate"
                                    control={control}
                                    defaultValue={endDate}
                                    render={() => (
                                        <DatePicker
                                            showTimeSelect
                                            selected={
                                                endDate
                                                    ? new Date(endDate)
                                                    : null
                                            }
                                            placeholderText="Select end date"
                                            onChange={(e) =>
                                                handleChange(e, 'endDate')
                                            }
                                        />
                                    )}
                                />
                            </Flex>
                        </Flex>
                        <Button
                            ml={{ base: 0, lg: 10 }}
                            isDisabled={
                                !locationId || !startDate || !endDate || loading
                            }
                            type={'submit'}
                            colorScheme="messenger"
                        >
                            View daily statistics
                        </Button>
                    </Flex>
                </form>
            </Flex>
            <Flex my={10} width={'full'}>
                {loading ? (
                    <Spinner size="xl" mx={'auto'} />
                ) : (
                    <Flex direction={'column'}>
                        <Flex
                            mb={10}
                            h={'max-content'}
                            flexDirection={'column'}
                        >
                            <CustomTable
                                columns={profitCols}
                                data={[
                                    {
                                        ...statistics,
                                        fiscalTotal:
                                            statistics.fiscalTotal -
                                            statistics.discountedTotal,
                                    },
                                ]}
                            />
                        </Flex>
                        <Flex flexDirection={{ base: 'column', lg: 'column' }}>
                            <Flex mb={10}>
                                <CustomTable
                                    columns={orderCols}
                                    data={statistics.orders || []}
                                />
                            </Flex>

                            <CustomTable
                                columns={cols}
                                data={Object.values(statistics.statistics)}
                            />
                        </Flex>
                    </Flex>
                )}
            </Flex>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Modal Title</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        Are you sure you want to delete this order?
                    </ModalBody>

                    <ModalFooter>
                        <Button
                            mr={3}
                            onClick={() => {
                                setIdToDelete('')
                                onClose()
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            colorScheme="blue"
                            onClick={() => {
                                dispatch(deleteOrderAction(idToDelete))
                                setIdToDelete('')
                                handleSubmit(onSubmit)()
                                onClose()
                            }}
                        >
                            Delete
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}
