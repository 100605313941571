import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { RootState } from '../../store'
import { fetchAllLocations } from '../Locations/locationsSlice'
import AdminOrdersView from './AdminOrdersView'
import {
    fetchAllAdminOrders,
    deleteOrderAsOwner,
    emptyAdminOrderList,
} from './adminOrdersSlice'
import { Flex, Spinner } from '@chakra-ui/react'
import { useInView } from 'react-intersection-observer'

export default () => {
    const dispatch = useAppDispatch()
    const locations = useAppSelector((state: RootState) => state.locations)
    const adminOrders = useAppSelector(
        (state: RootState) => state.adminOrderSlice
    )
    const [selectedLocation, setSelectedLocation] = useState('')
    const [fetchingData, setFetchingData] = useState(true)
    const { ref, inView, entry } = useInView({
        threshold: 0,
        delay: 0.5,
    })
    useEffect(() => {
        dispatch(fetchAllLocations()).then((locationsData) => {
            if (locationsData.payload?.length === 1) {
                setSelectedLocation(locationsData.payload[0].locationId)
            }
        })
    }, [])

    const handleLocationChange = (locationId: string) => {
        setFetchingData(true)
        dispatch(emptyAdminOrderList())
        if (locationId.length) {
            dispatch(
                fetchAllAdminOrders({
                    locationId,
                    orderId: adminOrders.lastKey.orderId,
                    createdAt: adminOrders.lastKey.createdAt,
                })
            )
        }
        setSelectedLocation(locationId)
        setFetchingData(false)
    }

    const handleDeleteOrder = async (orderId: string) => {
        await dispatch(deleteOrderAsOwner({ orderId }))
        await dispatch(
            fetchAllAdminOrders({
                locationId: selectedLocation,
                orderId: adminOrders.lastKey.orderId,
                createdAt: adminOrders.lastKey.createdAt,
            })
        )
    }
    useEffect(() => {
        if (inView && !fetchingData && !adminOrders.reachedEnd) {
            setFetchingData(true)
            dispatch(
                fetchAllAdminOrders({
                    locationId: selectedLocation,
                    orderId: adminOrders.lastKey.orderId,
                    createdAt: adminOrders.lastKey.createdAt,
                })
            )
            setFetchingData(false)
        }
    }, [inView])
    return (
        <>
            <AdminOrdersView
                locations={locations.data || []}
                loading={locations.isLoading || adminOrders.isLoading}
                selectedLocation={selectedLocation}
                setSelectedLocation={handleLocationChange}
                orders={adminOrders.data}
                handleDeleteOrder={handleDeleteOrder}
            />
            {(!adminOrders.reachedEnd || fetchingData) && (
                <Flex
                    alignItems={'center'}
                    justifyContent={'center'}
                    w="full"
                    ref={ref}
                >
                    <Flex w={'full'}>
                        <Spinner size="xl" mx={'auto'} />
                    </Flex>
                </Flex>
            )}
        </>
    )
}
