import React from 'react'
import OrderCard from './components/OrderCard/OrderCard'
import { Button, Divider, Flex, Grid, Spinner, Text } from '@chakra-ui/react'
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs'
import { setOrder } from '../OrderForm/orderFormSlice'
import OrderForm from '../OrderForm/OrderForm'
import { useAppDispatch } from '../../hooks'
import { useNavigate } from 'react-router-dom'
import FiscalPrinterStatus from '../../components/FiscalPrinterStatus/FiscalPrinterStatus'

interface IOrders {
    isConnected: boolean
    orders: any[]
    isOrderFormVisible: boolean
    menu?: any[]
    loading: boolean
    handleHideOrder: Function
}
export default ({
    isConnected,
    orders,
    isOrderFormVisible,
    menu,
    loading,
    handleHideOrder,
}: IOrders) => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    return (
        <>
            <Breadcrumbs />
            <Divider my={10} />
            {isConnected ? (
                <>
                    <Flex my={10} justifyContent={'space-between'}>
                        <FiscalPrinterStatus />
                        <Button
                            colorScheme={'messenger'}
                            onClick={() => {
                                dispatch(
                                    setOrder({
                                        id: '',
                                        status: 'DRAFT',
                                        items: [],
                                    })
                                )
                                navigate('/dashboard/orders/create')
                            }}
                        >
                            Create Order
                        </Button>
                    </Flex>
                    {loading && (
                        <Flex w={'full'}>
                            <Spinner size="xl" mx={'auto'} />
                        </Flex>
                    )}
                    <Flex
                        flexWrap={'wrap'}
                        gap={6}
                        p={5}
                        alignItems={'baseline'}
                    >
                        {orders.map((order) => (
                            <OrderCard
                                shortId={order.shortOrderId}
                                id={order.orderId}
                                status={order.orderStatus}
                                user={order.orderName || order.userId}
                                items={order.items}
                                menu={menu}
                                location={order.orderPlace}
                                createdAt={order.createdAt}
                                hideOrder={handleHideOrder}
                            />
                        ))}
                    </Flex>
                </>
            ) : (
                <Flex
                    direction={'column'}
                    justifyContent={'center'}
                    alignItems={'center'}
                >
                    <Text>Please wait while we connect to the server</Text>
                    {/*<Spinner*/}
                    {/*    thickness="4px"*/}
                    {/*    speed="0.65s"*/}
                    {/*    emptyColor="gray.200"*/}
                    {/*    color="blue.500"*/}
                    {/*    size="xl"*/}
                    {/*/>*/}
                </Flex>
            )}
        </>
    )
}
