import { useAppDispatch, useAppSelector } from '../../hooks'
import { RootState } from '../../store'
import { useEffect } from 'react'
import {
    deleteProductAction,
    fetchAllProducts,
    setProductsAction,
} from './productsSlice'
import ProductsView from './ProductsView'
import { setProduct } from '../ProductForm/productFormSlice'
import { useToast } from '@chakra-ui/react'

export default () => {
    const dispatch = useAppDispatch()
    const products = useAppSelector((state: RootState) => state.products)
    const productForm = useAppSelector((state: RootState) => state.productForm)
    const toast = useToast()
    useEffect(() => {
        dispatch(fetchAllProducts())
    }, [])
    const setProductForm = (data: any) => {
        dispatch(setProduct(data))
    }
    const deleteItem = async (productId: string) => {
        try {
            await dispatch(deleteProductAction(productId))
            dispatch(fetchAllProducts())
            toast({
                title: 'Product',
                description:
                    'Product was deleted successfully. Please remove it from the locations it was added to.',
                status: 'success',
                duration: 9000,
                isClosable: true,
            })
        } catch (e) {
            toast({
                title: 'Product',
                description:
                    'Whoops! Something went wrong when deleting the product',
                status: 'error',
                duration: 9000,
                isClosable: true,
            })
        }
    }
    return (
        <ProductsView
            data={products.data}
            isProductFormVisible={productForm.isVisible}
            setProductForm={setProductForm}
            deleteItem={deleteItem}
        />
    )
}
