import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
    createProduct,
    IProduct,
    updateProduct,
} from '../../repository/products'
import { createUser, IUser, updateUser } from '../../repository/users'

interface IUserSlice {
    isLoading: boolean
    data: IUser
    error: boolean
    isVisible: boolean
}

const initialState: IUserSlice = {
    isLoading: false,
    data: {
        email: '',
        displayName: '',
        phone: '',
        role: 0,
        active: false,
        location: '',
    },
    isVisible: false,
    error: false,
}

export const createUserAction = createAsyncThunk(
    'userForm/createUser',
    async (data: IUser, thunkAPI) => {
        const response = data.userId
            ? await updateUser(data.userId, data)
            : await createUser(data)
        return response.data
    }
)
export const userFormSlice = createSlice({
    name: 'userForm',
    initialState,
    reducers: {
        setUser: (state, action) => {
            state.data = action.payload
            state.isVisible = true
        },
        cancelAction: (state) => initialState,
        completeAction: (
            state,
            action: PayloadAction<{
                id: string
                address: string
                contact: string
            }>
        ) => initialState,
    },
})

export const { setUser, completeAction, cancelAction } = userFormSlice.actions
export default userFormSlice.reducer
