import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { login } from '../../repository/auth'
import {
    logoutUser,
    saveAccount,
    saveTokenExpiryTime,
    saveUserToken,
} from '../../services/account'

interface ILoginSlice {
    isLoading: boolean
    error: boolean | string
}

export const loginUser = createAsyncThunk(
    'login/loginAction',
    async (
        {
            email,
            password,
            rememberMe,
        }: { email: string; password: string; rememberMe: boolean },
        thunkAPI
    ): Promise<any | string> => {
        try {
            const response = await login(email, password, rememberMe)

            return response.data
        } catch (e: any) {
            switch (e.response.status) {
                case 401:
                    return thunkAPI.rejectWithValue(
                        'Wrong username or password'
                    )
                case 500:
                default:
                    return thunkAPI.rejectWithValue(
                        'Whoops! Something went wrong. Please try again later'
                    )
            }
        }
    }
)

const initialState: ILoginSlice = {
    isLoading: false,
    error: false,
}
const loginSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {
        logOut: () => {
            logoutUser()
            window.location.href = '/login'
        },
    },
    extraReducers: (builder) =>
        builder
            .addCase(loginUser.pending, (state, action) => {
                state.isLoading = true
                state.error = false
            })
            .addCase(loginUser.fulfilled, (state, action) => {
                state.isLoading = false
                state.error = false
                const { token, payload } = action.payload
                saveUserToken(token)
                saveTokenExpiryTime(payload.expiresIn)
                saveAccount(payload)
                window.location.href = '/dashboard'
            })
            .addCase(loginUser.rejected, (state, action) => {
                state.isLoading = false
                // @ts-ignore
                state.error = action.payload
            }),
})

export const { logOut } = loginSlice.actions

export default loginSlice.reducer
