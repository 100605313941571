import LocationsView from './LocationsView'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { RootState } from '../../store'
import { useEffect } from 'react'
import { fetchAllLocations, setLocationsAction } from './locationsSlice'
import locationsView from './LocationsView'
import { ILocation } from '../../repository/locations'
import { createLocationAction } from '../LocationForm/locationFormSlice'
import { fetchAllProducts } from '../Products/productsSlice'

export default () => {
    const dispatch = useAppDispatch()
    const locations = useAppSelector((state: RootState) => state.locations)
    const locationForm = useAppSelector(
        (state: RootState) => state.locationForm
    )
    useEffect(() => {
        dispatch(fetchAllLocations())
        dispatch(fetchAllProducts())
    }, [])

    return (
        <LocationsView
            data={locations.data}
            isLocationFormVisible={locationForm.isVisible}
        />
    )
}
