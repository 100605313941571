import React, { useEffect, useState } from 'react'
import { Flex, MenuButton, MenuItem, Text } from '@chakra-ui/react'
import { Icon } from '@chakra-ui/icons'
import { Link, useLocation } from 'react-router-dom'

interface IMenuItem {
    link: string
    icon: any
    text: string
    isOpened: boolean
}
export default (props: IMenuItem) => {
    const location = useLocation()
    const [active, setActive] = useState(location.pathname === props.link)
    useEffect(() => {
        setActive(location.pathname === props.link)
    }, [location.pathname])
    return (
        <Flex
            backgroundColor={active ? 'messenger.500' : '#fff'}
            p={3}
            borderRadius={8}
            my={0.5}
            _hover={{
                textDecor: 'none',
                backgroundColor: 'messenger.500',
                color: '#fff',
            }}
        >
            <Link to={props.link}>
                <MenuButton w="100%" display={'flex'} justifyContent={'center'}>
                    <Flex
                        alignItems={'center'}
                        justifyContent={
                            props.isOpened ? 'flex-start' : 'center'
                        }
                    >
                        <Icon
                            as={props.icon}
                            fontSize="xl"
                            color={active ? '#fff' : 'gray.500'}
                        />
                        <Text
                            ml={5}
                            fontSize="md"
                            display={!props.isOpened ? 'none' : 'flex'}
                            color={active ? '#fff' : 'gray.500'}
                        >
                            {props.text}
                        </Text>
                    </Flex>
                </MenuButton>
            </Link>
        </Flex>
    )
}
