import React, { useEffect, useState } from 'react'
import { Box, Flex, useDisclosure } from '@chakra-ui/react'
import Sidebar from '../components/Sidebar/Sidebar'
import { Navigate, Outlet } from 'react-router-dom'
import { isAuth } from '../services/auth'
import useWebSocket, { ReadyState } from 'react-use-websocket'

export default () => {
    const { isOpen, onToggle } = useDisclosure()
    if (!isAuth()) {
        return <Navigate to={'/login'} replace />
    }
    const token = isAuth()
    const WS_URL = `${
        process.env.REACT_APP_SOCKET_SERVER || 'ws://127.0.0.1:3001'
    }/?token=${token}`
    const { sendJsonMessage, lastJsonMessage, readyState } = useWebSocket(
        WS_URL,
        {
            share: false,
            shouldReconnect: () => true,
        }
    )
    const [newOrder, setNewOrder] = useState(false)

    useEffect(() => {
        console.log('Connection state changed', readyState, ReadyState)
        if (readyState === ReadyState.OPEN) {
            sendJsonMessage({
                event: 'subscribe',
                data: {
                    channel: 'general-chatroom',
                },
            })
        }
    }, [readyState])

    // Run when a new WebSocket message is received (lastJsonMessage)
    useEffect(() => {
        console.log(`Got a new message: ${lastJsonMessage}`)
        switch (lastJsonMessage) {
            case 'orderCreated':
                setNewOrder(true)
                break
            default:
                setNewOrder(false)
                break
        }
    }, [lastJsonMessage])
    return (
        <Flex style={{ background: '#f4f7fe' }}>
            <Sidebar
                isOpen={isOpen}
                onToggle={onToggle}
                newOrder={newOrder}
                setNewOrder={setNewOrder}
            />
            <Box w="100%" p={10} overflowX={'scroll'}>
                <Outlet />
            </Box>
        </Flex>
    )
}
