import * as React from 'react'
import Dashboard from '../containers/Dashboard/Dashboard'
import { Navigate } from 'react-router-dom'
import Orders from '../containers/Orders/Orders'
import OnlineOrders from '../containers/OnlineOrders/Orders'
import Locations from '../containers/Locations/Locations'
import Users from '../containers/Users/Users'
import Products from '../containers/Products/Products'
import OrderForm from '../containers/OrderForm/OrderForm'
import Statistics from '../containers/Statistics/Statistics'
import AdminOrders from '../containers/AdminOrders/AdminOrders'
import OnlineUsers from '../containers/OnlineUsers/OnlineUsers'

export const AdminRoutes = [
    {
        path: '/dashboard',
        element: <Dashboard />,
        index: true,
    },
    {
        path: '/dashboard/locations',
        element: <Locations />,
        index: true,
    },
    {
        path: '/dashboard/users',
        element: <Users />,
        index: true,
    },
    {
        path: '/dashboard/orders',
        element: <Orders />,
        index: true,
    },
    {
        path: '/dashboard/orders/online',
        element: <OnlineOrders />,
        index: true,
    },
    {
        path: '/dashboard/users/online',
        element: <OnlineUsers />,
        index: true,
    },
    {
        path: '/dashboard/orders/create',
        element: <OrderForm />,
        index: true,
    },
    {
        path: '/dashboard/admin/orders/',
        element: <AdminOrders />,
        index: true,
    },
    {
        path: '/dashboard/products',
        element: <Products />,
        index: true,
    },
    {
        path: '/dashboard/statistics',
        element: <Statistics />,
        index: true,
    },
]
