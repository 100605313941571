import React, { useState } from 'react'
import {
    Box,
    Button,
    Divider,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    Flex,
    IconButton,
    Input,
    Link,
    Menu,
    MenuButton,
    MenuIcon,
    PopoverArrow,
    Slide,
    useDisclosure,
} from '@chakra-ui/react'
import { ImEye } from 'react-icons/im'
import { ArrowLeftIcon, ArrowRightIcon, Icon } from '@chakra-ui/icons'
import { MdSettings } from 'react-icons/md'
import SidebarMenuItem from './components/MenuItem/SidebarMenuItem'
import {
    FaHome,
    FaMapMarker,
    FaUser,
    FaClipboardList,
    FaCarrot,
    FaPowerOff,
    FaStamp,
} from 'react-icons/fa'
import { useAppDispatch } from '../../hooks'
import { logOut } from '../../containers/Login/loginSlice'
import { getLocalAccount, USER_ROLES } from '../../services/account'

export default ({
    isOpen,
    onToggle,
    newOrder,
    setNewOrder,
}: {
    isOpen: boolean
    onToggle: any
    newOrder: boolean
    setNewOrder: Function
}) => {
    const dispatch = useAppDispatch()
    const [isOpened, setIsOpened] = useState(
        JSON.parse(window.localStorage.getItem('sidebarOpened') || 'true')
    )
    const localAcc = getLocalAccount()
    const MenuItems = [
        {
            link: '/dashboard',
            text: 'Dashboard',
            icon: FaHome,
            visible: [USER_ROLES.OWNER, USER_ROLES.ADMIN].includes(
                localAcc.role
            ),
        },
        {
            link: '/dashboard/locations',
            text: 'Locations',
            icon: FaMapMarker,
            visible: [USER_ROLES.OWNER].includes(localAcc.role),
        },
        {
            link: '/dashboard/users',
            text: 'Users',
            icon: FaUser,
            visible: [USER_ROLES.OWNER].includes(localAcc.role),
        },
        {
            link: '/dashboard/users/online',
            text: 'Online Users',
            icon: FaUser,
            visible: [USER_ROLES.OWNER, USER_ROLES.ADMIN].includes(
                localAcc.role
            ),
        },
        {
            link: '/dashboard/orders',
            text: 'Orders',
            icon: FaClipboardList,
            visible: [USER_ROLES.ADMIN].includes(localAcc.role),
        },
        {
            link: '/dashboard/orders/online',
            text: `Online ${newOrder ? '(New order)' : ''}`,
            icon: FaClipboardList,
            visible: [USER_ROLES.ADMIN].includes(localAcc.role),
        },
        {
            link: '/dashboard/products',
            text: 'Products',
            icon: FaCarrot,
            visible: [USER_ROLES.OWNER].includes(localAcc.role),
        },
        {
            link: '/dashboard/statistics',
            text: 'Statistics',
            icon: FaStamp,
            visible: [USER_ROLES.OWNER].includes(localAcc.role),
        },
        {
            link: '/dashboard/admin/orders',
            text: 'Admin Orders',
            icon: FaClipboardList,
            visible: [USER_ROLES.OWNER].includes(localAcc.role),
        },
    ]
    const toggleSidebar = () => {
        window.localStorage.setItem('sidebarOpened', JSON.stringify(!isOpened))
        setIsOpened(!isOpened)
    }
    return (
        <Flex
            direction={'column'}
            pos="sticky"
            top={0}
            // left="5"
            h="100vh"
            // marginTop="2.5vh"
            w={isOpened ? '200px' : '75px'}
            flexDir="column"
            bg={'#fff'}
        >
            <Flex flexDir="column" w="100%" alignItems={'flex-end'}>
                <IconButton
                    background="none"
                    _hover={{ background: 'none' }}
                    icon={isOpened ? <ArrowLeftIcon /> : <ArrowRightIcon />}
                    style={{
                        position: 'relative',
                        right: '-20px',
                        padding: '5px',
                        borderRadius: '50%',
                        background: '#fafafa',
                    }}
                    aria-label={'icon-button'}
                    onClick={toggleSidebar}
                />
            </Flex>
            <Flex
                w="100%"
                justifyContent={'center'}
                direction={'column'}
                h={'100px'}
            ></Flex>
            <Divider />
            <Flex w="100%" p={2} justifyContent={'center'} direction={'column'}>
                <Menu>
                    {MenuItems.map(
                        (menuItem) =>
                            menuItem.visible && (
                                <div
                                    onClick={() => {
                                        if (
                                            menuItem.link ===
                                            '/dashboard/orders/online'
                                        ) {
                                            setNewOrder(false)
                                        }
                                    }}
                                >
                                    <SidebarMenuItem
                                        key={menuItem.text}
                                        icon={menuItem.icon}
                                        link={menuItem.link}
                                        text={menuItem.text}
                                        isOpened={isOpened}
                                    />
                                </div>
                            )
                    )}
                </Menu>
            </Flex>
            <Flex
                w="100%"
                justifyContent={'center'}
                direction={'column'}
                mt={'auto'}
                p={2}
            >
                <IconButton
                    onClick={() => dispatch(logOut())}
                    aria-label={'logout'}
                    icon={<Icon as={FaPowerOff} />}
                />
            </Flex>
        </Flex>
    )
}
