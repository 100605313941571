import API from '../services/api'

export interface IProduct {
    productId?: string
    name: string
    productType: string
    description: string
    image: string
    price: number
    imageSelected?: File
    taxRate: string
    maxNumberOfMeats: number
    maxNumberOfSauces: number
}
export const getProducts = async () => {
    return await API.get('/product')
}

export const createProduct = async (data: FormData) => {
    return API.post('/product/create', data, {
        'content-type': 'multipart/form-data',
    })
}

export const updateProduct = async (id: string, data: FormData) => {
    return API.put(`/product/${id}`, data, {
        'content-type': 'multipart/form-data',
    })
}

export const deleteProduct = async (productId: string) => {
    return await API.delete(`/product/${productId}`)
}
