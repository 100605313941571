import React from 'react'
import { Box, Button, Divider, Flex, IconButton } from '@chakra-ui/react'
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs'
import CustomTable from '../../components/CustomTable/CustomTable'
import LocationForm from '../LocationForm/LocationForm'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { setLocation } from '../LocationForm/locationFormSlice'
import { ImEye } from 'react-icons/im'
import { RootState } from '../../store'

export default ({
    data,
    isLocationFormVisible,
}: {
    data: any[]
    isLocationFormVisible: boolean
}) => {
    const dispatch = useAppDispatch()
    const setLocationForm = (data: any) => {
        dispatch(setLocation(data))
    }
    const cols = [
        { label: 'id', field: 'locationId' },
        { label: 'Name', field: 'name' },
        { label: 'Address', field: 'address' },
        { label: 'Phone', field: 'phone' },
        {
            label: 'Actions',
            field: '',
            type: 'icons',
            icons: [
                {
                    show: true,
                    icon: <ImEye />,
                    callbackMethod: setLocationForm,
                },
            ],
        },
    ]
    return (
        <>
            <Breadcrumbs />
            <Divider my={30} />
            <Flex my={10} justifyContent={'flex-end'}>
                <Button
                    colorScheme={'messenger'}
                    onClick={() =>
                        dispatch(
                            setLocation({ id: '', address: '', contact: '' })
                        )
                    }
                >
                    Create Location
                </Button>
            </Flex>
            <CustomTable columns={cols} data={data} />
            {isLocationFormVisible && <LocationForm key={Date.now()} />}
        </>
    )
}
