import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
    createLocation,
    ILocation,
    updateLocation,
} from '../../repository/locations'

interface ILocationsSlice {
    isLoading: boolean
    data: {
        id: string
        address: string
        phone: string
        name: string
        menu: any[]
    }
    error: boolean
    isVisible: boolean
}

const initialState: ILocationsSlice = {
    isLoading: false,
    data: {
        id: '',
        address: '',
        phone: '',
        name: '',
        menu: [],
    },
    isVisible: false,
    error: false,
}

export const createLocationAction = createAsyncThunk(
    'locations/createLocation',
    async (data: ILocation, thunkAPI) => {
        const response = data.locationId
            ? await updateLocation(data.locationId, data)
            : await createLocation(data)
        return response.data
    }
)
export const locationFormSlice = createSlice({
    name: 'locationsForm',
    initialState,
    reducers: {
        setLocation: (state, action) => {
            state.data = action.payload
            state.isVisible = true
        },
        cancelAction: (state) => initialState,
        completeAction: (
            state,
            action: PayloadAction<{
                id: string
                address: string
                contact: string
            }>
        ) => initialState,
    },
})

export const { setLocation, completeAction, cancelAction } =
    locationFormSlice.actions
export default locationFormSlice.reducer
