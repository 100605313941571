import React from 'react'
import Splash from '../../layouts/Splash'
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Heading,
    Input,
    Link,
    Spinner,
    Text,
} from '@chakra-ui/react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useAppDispatch } from '../../hooks'
import { completeResetPasswordAction } from './resetPasswordSlice'

type ResetPasswordInputs = {
    password: string
    confirmPassword: string
}
export default ({
    token,
    error,
    isLoading,
    success,
}: {
    token: string
    error: boolean
    isLoading: boolean
    success: boolean
}) => {
    const dispatch = useAppDispatch()
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm<ResetPasswordInputs>()
    const onSubmit: SubmitHandler<ResetPasswordInputs> = (data) =>
        dispatch(completeResetPasswordAction({ token, ...data }))
    return token ? (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Card size={'lg'}>
                <CardHeader>
                    <Heading as="h2" size="xl">
                        Please enter your new password.
                    </Heading>
                </CardHeader>

                {!isLoading ? (
                    success ? (
                        <Flex
                            p={5}
                            justifyContent={'center'}
                            flexDirection={'column'}
                        >
                            <Text textAlign={'center'}>
                                Password changed successfully.
                            </Text>
                            <Link
                                href={'/login'}
                                color={'blue'}
                                mt={6}
                                alignSelf={'flex-end'}
                            >
                                Go back
                            </Link>
                        </Flex>
                    ) : (
                        <>
                            <CardBody>
                                <FormControl
                                    isInvalid={!!errors.password}
                                    mb={'30px'}
                                >
                                    <FormLabel>
                                        Password<span>*</span>
                                    </FormLabel>
                                    <Input
                                        type={'password'}
                                        {...register('password', {
                                            required: true,
                                        })}
                                    />
                                    {!!errors.password && (
                                        <FormErrorMessage>
                                            Password is required.
                                        </FormErrorMessage>
                                    )}
                                </FormControl>
                                <FormControl
                                    isInvalid={!!errors.confirmPassword}
                                    mb={'30px'}
                                >
                                    <FormLabel>
                                        Password<span>*</span>
                                    </FormLabel>
                                    <Input
                                        type={'password'}
                                        {...register('confirmPassword', {
                                            required: true,
                                        })}
                                    />
                                    {!!errors.confirmPassword && (
                                        <FormErrorMessage>
                                            Confirmed password is required.
                                        </FormErrorMessage>
                                    )}
                                </FormControl>
                            </CardBody>
                            <CardFooter>
                                <Button
                                    type={'submit'}
                                    colorScheme="messenger"
                                    width={'100%'}
                                >
                                    Sign in
                                </Button>
                            </CardFooter>
                        </>
                    )
                ) : (
                    <Flex p={4}>
                        <Spinner
                            thickness="4px"
                            speed="0.65s"
                            emptyColor="gray.200"
                            color="blue.500"
                            size="xl"
                            mx={'auto'}
                        />
                    </Flex>
                )}
            </Card>
        </form>
    ) : (
        <Card size={'lg'}>
            <CardHeader>
                <Heading as="h2" size="xl">
                    Missing token
                </Heading>
            </CardHeader>
            <CardBody>
                <Heading as={'span'}>
                    No token present. Please check the link and come back again.
                </Heading>
            </CardBody>
        </Card>
    )
}
