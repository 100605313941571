export const saveAccount = (account: any) => {
    window.localStorage.setItem('account', JSON.stringify(account))
}

export enum USER_ROLES {
    OWNER,
    ADMIN,
    USER,
}

export const getLocalAccount = () => {
    const account: any = window.localStorage.getItem('account')
    return JSON.parse(account)
}

export const saveUserToken = (token: string) => {
    localStorage.setItem('token', token)
}

export const saveTokenExpiryTime = (time: string) => {
    localStorage.setItem('expiresIn', time)
}

export const logoutUser = () => {
    localStorage.removeItem('token')
}

export const isAuth = () => {
    return localStorage.getItem('token')
}

export const isOwner = () => {
    const account = getLocalAccount()
    return account.role === USER_ROLES.OWNER
}

export const isAdmin = () => {
    const account = getLocalAccount()
    return account.role === USER_ROLES.ADMIN
}

export const isUser = () => {
    return !isOwner() && !isAdmin()
}
