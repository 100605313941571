import API from '../services/api'

export const login = async (
    email: string,
    password: string,
    rememberMe: boolean
) => {
    return await API.post('/auth/login', {
        email,
        password,
        rememberMe,
    })
}
