import React, { useState } from 'react'
import {
    Box,
    Button,
    Divider,
    Flex,
    IconButton,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    useDisclosure,
} from '@chakra-ui/react'
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs'
import CustomTable from '../../components/CustomTable/CustomTable'
import LocationForm from '../LocationForm/LocationForm'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { setLocation } from '../LocationForm/locationFormSlice'
import { ImEye } from 'react-icons/im'
import { setProduct } from '../ProductForm/productFormSlice'
import ProductForm from '../ProductForm/ProductForm'
import { DeleteIcon } from '@chakra-ui/icons'
import { deleteProductAction } from './productsSlice'

export default ({
    data,
    isProductFormVisible,
    setProductForm,
    deleteItem,
}: {
    data: any[]
    isProductFormVisible: boolean
    setProductForm: Function
    deleteItem: Function
}) => {
    const dispatch = useAppDispatch()
    const [itemToDelete, setItemToDelete] = useState(null as null | any)

    const { isOpen, onOpen, onClose } = useDisclosure()
    const stageItemForDeletion = (data: any) => {
        onOpen()
        setItemToDelete(data)
    }
    const confirmDeleteItem = () => {
        deleteItem(itemToDelete.productId)
        setItemToDelete(null)
        onClose()
    }

    const cols = [
        { label: 'id', field: 'productId' },
        { label: 'Name', field: 'name' },
        { label: 'Type', field: 'productType' },
        { label: 'Price', field: 'price' },
        { label: 'Tax Rate', field: 'taxRate' },
        {
            label: 'Actions',
            field: '',
            type: 'icons',
            icons: [
                {
                    show: true,
                    icon: <ImEye />,
                    callbackMethod: setProductForm,
                },
                {
                    show: true,
                    icon: <DeleteIcon />,
                    callbackMethod: stageItemForDeletion,
                },
            ],
        },
    ]
    return (
        <>
            <Breadcrumbs />
            <Divider my={30} />
            <Flex my={10} justifyContent={'flex-end'}>
                <Button
                    colorScheme={'messenger'}
                    onClick={() =>
                        dispatch(
                            setProduct({
                                id: '',
                                type: '',
                                price: '',
                                name: '',
                                image: '',
                                description: '',
                                maxNumberOfMeats: 0,
                                maxNumberOfSauces: 0,
                            })
                        )
                    }
                >
                    Create Product
                </Button>
            </Flex>
            <CustomTable columns={cols} data={data} />
            {isProductFormVisible && <ProductForm key={Date.now()} />}
            {isOpen && (
                <Modal isOpen={isOpen} onClose={onClose}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>Delete a product</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            Are you sure that you want to delete this product?
                        </ModalBody>

                        <ModalFooter>
                            <Button variant="ghost" mr={3} onClick={onClose}>
                                No
                            </Button>
                            <Button
                                colorScheme="messenger"
                                onClick={confirmDeleteItem}
                            >
                                Yes
                            </Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            )}
        </>
    )
}
