import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Checkbox,
    Flex,
    FormControl,
    FormErrorMessage,
    FormHelperText,
    FormLabel,
    Heading,
    IconButton,
    Image,
    Input,
    InputGroup,
    InputRightElement,
    Link,
    Text,
} from '@chakra-ui/react'
import { ImEye, ImEyeBlocked } from 'react-icons/im'
import React from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { Navigate } from 'react-router-dom'
import Splash from '../../layouts/Splash'
import { useAppDispatch } from '../../hooks'
import { loginUser } from './loginSlice'
type LoginInputs = {
    email: string
    password: string
    rememberMe: boolean
}
export const LoginView = ({ error }: { error: any }) => {
    const [show, setShow] = React.useState(false)
    const dispatch = useAppDispatch()
    const handleClick = () => setShow(!show)
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm<LoginInputs>()
    const onSubmit: SubmitHandler<LoginInputs> = (data) =>
        dispatch(loginUser(data))

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Card size={'lg'}>
                <CardHeader>
                    <Heading as="h2" size="xl">
                        Sign In
                    </Heading>
                </CardHeader>
                <CardBody>
                    <FormControl isInvalid={!!errors.email} mb={'30px'}>
                        <FormLabel>
                            Email<span>*</span>
                        </FormLabel>
                        <Input
                            type={'email'}
                            {...register('email', { required: true })}
                        />
                        {!!errors.email && (
                            <FormErrorMessage>
                                Email is required.
                            </FormErrorMessage>
                        )}
                    </FormControl>
                    <FormControl isInvalid={!!errors.password} mb={'30px'}>
                        <FormLabel>
                            Password<span>*</span>
                        </FormLabel>
                        <InputGroup size="md">
                            <Input
                                pr="4.5rem"
                                type={show ? 'text' : 'password'}
                                placeholder="Enter password"
                                {...register('password', {
                                    required: true,
                                })}
                            />
                            <InputRightElement width="4.5rem">
                                <Button
                                    h="1.75rem"
                                    size="sm"
                                    onClick={handleClick}
                                >
                                    {show ? <ImEye /> : <ImEyeBlocked />}
                                </Button>
                            </InputRightElement>
                        </InputGroup>
                        {!!errors.password && (
                            <FormErrorMessage>
                                Password is required.
                            </FormErrorMessage>
                        )}
                    </FormControl>
                    <Flex>
                        <Checkbox {...register('rememberMe')} mr={'30px'}>
                            Keep me logged in
                        </Checkbox>
                        <Flex>
                            <Link href="/reset-password" color={'blue'}>
                                Forgot password?
                            </Link>
                        </Flex>
                    </Flex>
                    {error && (
                        <Text mt={10} color={'red'}>
                            {error}
                        </Text>
                    )}
                </CardBody>

                <CardFooter>
                    <Button
                        type={'submit'}
                        colorScheme="messenger"
                        width={'100%'}
                    >
                        Sign in
                    </Button>
                </CardFooter>
            </Card>
        </form>
    )
}
