import {
    Button,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Image,
    Input,
    InputGroup,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Select,
    Text,
    useDisclosure,
    useToast,
} from '@chakra-ui/react'
import React, { useEffect, useRef, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { RootState } from '../../store'
import {
    cancelAction,
    createProductAction,
    TAX_RATES,
} from './productFormSlice'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { IProduct } from '../../repository/products'
import {
    AutoComplete,
    AutoCompleteInput,
    AutoCompleteItem,
    AutoCompleteList,
} from '@choc-ui/chakra-autocomplete'
import { fetchAllProducts } from '../Products/productsSlice'
import { ProductType } from '../OrderForm/components/MenuPage/MenuPage'

const FOOD_TYPES = [
    'FOOD',
    'DRINK',
    'EXTRAS',
    'MEAT',
    'SAUCE',
    'DESSERT',
    'SIDE',
    'SEASONAL',
    'COMBO',
]
export default () => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const finalRef = useRef(null)
    const dispatch = useAppDispatch()
    const toast = useToast()
    const productForm = useAppSelector((state: RootState) => state.productForm)

    const {
        register,
        handleSubmit,
        watch,
        control,
        setValue,
        formState: { errors },
    } = useForm<IProduct>({ defaultValues: { ...productForm.data } })
    const fileUploadRef = useRef<HTMLInputElement>(null)
    const selectedFoodType = watch('productType')
    const closeModal = () => {
        dispatch(cancelAction())
        onClose()
    }

    const [image, setImage] = useState<string | null>(
        productForm.data.image || '/images-static/cloud-upload.jpeg'
    )
    const [selectedImage, setSelectedImage] = useState(
        productForm.data.image || (null as null | Blob)
    )
    const onImageChange = (event: any) => {
        if (event.target.files && event.target.files[0]) {
            setSelectedImage(event.target.files[0])
            setImage(URL.createObjectURL(event.target.files[0]))
        }
    }

    const convertImageToBlob = async () => {
        if (productForm.data.image?.length) {
            try {
                // let blob = await fetch(productForm.data.image).then((r) =>
                //     r.blob()
                // )
                // setSelectedImage(blob)
            } catch (e) {}
        }
    }

    useEffect(() => {
        convertImageToBlob()
    }, [productForm.data.image])
    const onSubmit: SubmitHandler<IProduct> = async (data) => {
        try {
            const formData = new FormData()
            formData.append('image', selectedImage || data.imageSelected || '')
            formData.append('name', data.name)
            formData.append('productType', data.productType)
            formData.append('description', data.description)
            formData.append('taxRate', data.taxRate)
            formData.append(
                'maxNumberOfMeats',
                (data.maxNumberOfMeats || 0) + ''
            )
            formData.append(
                'maxNumberOfSauces',
                (data.maxNumberOfSauces || 0) + ''
            )
            formData.append('price', data.price + '')
            if (data.productId) {
                formData.append('productId', data.productId)
            }
            await dispatch(createProductAction(formData))
            dispatch(fetchAllProducts())
            closeModal()
            toast({
                title: 'Product',
                description: data.productId
                    ? 'Product was edited successfully!'
                    : 'Product was created successfully!',
                status: 'success',
                duration: 9000,
                isClosable: true,
            })
        } catch (e) {
            toast({
                title: 'Product',
                description: data.productId
                    ? 'Product was not edited!'
                    : 'Product was not created!',
                status: 'error',
                duration: 9000,
                isClosable: true,
            })
        }
    }
    return (
        <>
            {productForm.isVisible && (
                <Modal
                    finalFocusRef={finalRef}
                    isOpen={productForm.isVisible}
                    onClose={closeModal}
                >
                    <ModalOverlay />
                    <ModalContent>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <ModalHeader>
                                {productForm.data.productId
                                    ? 'Edit a product'
                                    : 'Create a product'}
                            </ModalHeader>
                            <ModalCloseButton />
                            <InputGroup size="md">
                                <Input
                                    type={'hidden'}
                                    {...register('productId')}
                                />
                            </InputGroup>
                            <ModalBody>
                                <FormControl isInvalid={!!errors.imageSelected}>
                                    <Controller
                                        control={control}
                                        rules={{
                                            required: true,
                                        }}
                                        render={({
                                            field: { onChange, onBlur, value },
                                        }) => (
                                            <>
                                                <Text>Pick an image</Text>
                                                {!!errors.image && (
                                                    <FormErrorMessage>
                                                        Image is required.
                                                    </FormErrorMessage>
                                                )}
                                                {image && (
                                                    <Image
                                                        mx={'auto'}
                                                        my={'50px'}
                                                        rounded={'50%'}
                                                        h={'100px'}
                                                        w={'100px'}
                                                        cursor={'pointer'}
                                                        src={image}
                                                        onClick={() => {
                                                            fileUploadRef.current?.click()
                                                        }}
                                                    />
                                                )}

                                                <Input
                                                    {...register('image', {
                                                        required:
                                                            'Recipe picture is required',
                                                    })}
                                                    ref={fileUploadRef}
                                                    src={productForm.data.image}
                                                    type="file"
                                                    id="image"
                                                    display={'none'}
                                                    onChange={(event) => {
                                                        onImageChange(event)
                                                        onChange(event)
                                                    }}
                                                />
                                            </>
                                        )}
                                        name={'image'}
                                    />
                                </FormControl>
                                <FormControl
                                    isInvalid={!!errors.name}
                                    mb={'30px'}
                                >
                                    <FormLabel>
                                        Name<span>*</span>
                                    </FormLabel>
                                    <InputGroup size="md">
                                        <Input
                                            pr="4.5rem"
                                            type={'text'}
                                            placeholder="Enter product name"
                                            {...register('name', {
                                                required: true,
                                            })}
                                        />
                                    </InputGroup>
                                    {!!errors.name && (
                                        <FormErrorMessage>
                                            Location name is required.
                                        </FormErrorMessage>
                                    )}
                                </FormControl>
                                <FormControl
                                    isInvalid={!!errors.description}
                                    mb={'30px'}
                                >
                                    <FormLabel>
                                        Description<span>*</span>
                                    </FormLabel>
                                    <InputGroup size="md">
                                        <Input
                                            pr="4.5rem"
                                            type={'text'}
                                            placeholder="Enter product description"
                                            {...register('description', {
                                                required: true,
                                            })}
                                        />
                                    </InputGroup>
                                    {!!errors.description && (
                                        <FormErrorMessage>
                                            Description is required.
                                        </FormErrorMessage>
                                    )}
                                </FormControl>
                                <FormControl
                                    isInvalid={!!errors.price}
                                    mb={'30px'}
                                >
                                    <FormLabel>
                                        Price<span>*</span>
                                    </FormLabel>
                                    <InputGroup size="md">
                                        <Input
                                            pr="4.5rem"
                                            type={'number'}
                                            placeholder="Enter product price"
                                            {...register('price', {
                                                required: true,
                                            })}
                                        />
                                    </InputGroup>
                                    {!!errors.price && (
                                        <FormErrorMessage>
                                            Product price is required.
                                        </FormErrorMessage>
                                    )}
                                </FormControl>

                                {[
                                    ProductType.FOOD + '',
                                    ProductType.COMBO + '',
                                ].includes(selectedFoodType) && (
                                    <>
                                        <FormControl
                                            isInvalid={
                                                !!errors.maxNumberOfMeats
                                            }
                                            mb={'30px'}
                                        >
                                            <FormLabel>
                                                Max number of meats
                                                <span>*</span>
                                            </FormLabel>
                                            <InputGroup size="md">
                                                <Input
                                                    pr="4.5rem"
                                                    type={'number'}
                                                    placeholder="Enter max number of meats"
                                                    {...register(
                                                        'maxNumberOfMeats',
                                                        {
                                                            required: true,
                                                        }
                                                    )}
                                                />
                                            </InputGroup>
                                            {!!errors.maxNumberOfMeats && (
                                                <FormErrorMessage>
                                                    Max number of meats is
                                                    required.
                                                </FormErrorMessage>
                                            )}
                                        </FormControl>
                                        <FormControl
                                            isInvalid={
                                                !!errors.maxNumberOfSauces
                                            }
                                            mb={'30px'}
                                        >
                                            <FormLabel>
                                                Max number of sauces
                                                <span>*</span>
                                            </FormLabel>
                                            <InputGroup size="md">
                                                <Input
                                                    pr="4.5rem"
                                                    type={'number'}
                                                    placeholder="Max number of sauces"
                                                    {...register(
                                                        'maxNumberOfSauces',
                                                        {
                                                            required: true,
                                                        }
                                                    )}
                                                />
                                            </InputGroup>
                                            {!!errors.maxNumberOfSauces && (
                                                <FormErrorMessage>
                                                    Max number of sauces is
                                                    required.
                                                </FormErrorMessage>
                                            )}
                                        </FormControl>
                                    </>
                                )}
                                <FormControl
                                    isInvalid={!!errors.productType}
                                    mb={'30px'}
                                >
                                    <FormLabel>
                                        Type<span>*</span>
                                    </FormLabel>
                                    <Controller
                                        control={control}
                                        rules={{
                                            required: true,
                                        }}
                                        render={({
                                            field: { onChange, onBlur, value },
                                        }) => (
                                            <>
                                                <AutoComplete
                                                    openOnFocus
                                                    onChange={(e) => {
                                                        setValue(
                                                            `productType`,
                                                            e
                                                        )
                                                        onChange(e)
                                                    }}
                                                    defaultValue={
                                                        productForm.data
                                                            .productType
                                                    }
                                                >
                                                    <AutoCompleteInput variant="filled" />
                                                    <AutoCompleteList>
                                                        {FOOD_TYPES.map(
                                                            (item, cid) => (
                                                                <AutoCompleteItem
                                                                    key={`option-${cid}`}
                                                                    value={item}
                                                                    textTransform="capitalize"
                                                                >
                                                                    {item}
                                                                </AutoCompleteItem>
                                                            )
                                                        )}
                                                    </AutoCompleteList>
                                                </AutoComplete>
                                                {!!errors.productType && (
                                                    <FormErrorMessage>
                                                        Product type is
                                                        required.
                                                    </FormErrorMessage>
                                                )}
                                            </>
                                        )}
                                        name={'productType'}
                                        defaultValue={
                                            productForm.data.productType
                                        }
                                    />
                                </FormControl>
                                <FormControl
                                    isInvalid={!!errors.taxRate}
                                    mb={'30px'}
                                >
                                    <FormLabel>
                                        Tax rate<span>*</span>
                                    </FormLabel>
                                    <Select
                                        placeholder="Select option"
                                        {...register('taxRate', {
                                            required: true,
                                        })}
                                    >
                                        {Object.keys(TAX_RATES).map(
                                            (taxRateKey, index) => (
                                                <option
                                                    value={
                                                        TAX_RATES[taxRateKey]
                                                    }
                                                    key={TAX_RATES[taxRateKey]}
                                                >
                                                    {taxRateKey}
                                                </option>
                                            )
                                        )}
                                    </Select>

                                    {!!errors.taxRate && (
                                        <FormErrorMessage>
                                            Role is required.
                                        </FormErrorMessage>
                                    )}
                                </FormControl>
                            </ModalBody>

                            <ModalFooter>
                                <Button mr={3} onClick={closeModal}>
                                    Cancel
                                </Button>
                                <Button colorScheme="messenger" type={'submit'}>
                                    Save
                                </Button>
                            </ModalFooter>
                        </form>
                    </ModalContent>
                </Modal>
            )}
        </>
    )
}
