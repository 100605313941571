import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
    createProduct,
    deleteProduct,
    IProduct,
    updateProduct,
} from '../../repository/products'
import {
    createLocation,
    ILocation,
    updateLocation,
} from '../../repository/locations'

interface IProductForm {
    isLoading: boolean
    data: IProduct
    error: boolean
    isVisible: boolean
}

export const TAX_RATES: any = {
    A: 'А',
    B: 'Б',
    V: 'В',
    G: 'Г',
}

const initialState: IProductForm = {
    isLoading: false,
    data: {
        productId: '',
        productType: '',
        price: 0,
        name: '',
        image: '',
        description: '',
        taxRate: TAX_RATES.G,
        maxNumberOfMeats: 0,
        maxNumberOfSauces: 0,
    },
    isVisible: false,
    error: false,
}
export const createProductAction = createAsyncThunk(
    'productForm/createProduct',
    async (data: FormData, thunkAPI) => {
        const response = !!data.get('productId')
            ? await updateProduct(data.get('productId') + '', data)
            : await createProduct(data)
        return response.data
    }
)
export const productFormSlice = createSlice({
    name: 'productForm',
    initialState,
    reducers: {
        setProduct: (state, action) => {
            state.data = action.payload
            state.isVisible = true
        },
        cancelAction: (state) => initialState,
        completeAction: (
            state,
            action: PayloadAction<{
                id: string
                address: string
                contact: string
            }>
        ) => initialState,
    },
})

export const { setProduct, completeAction, cancelAction } =
    productFormSlice.actions
export default productFormSlice.reducer
