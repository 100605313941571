import React from 'react'
import { Box, Button, Divider, Flex, IconButton } from '@chakra-ui/react'
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs'
import CustomTable from '../../components/CustomTable/CustomTable'
import LocationForm from '../LocationForm/LocationForm'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { setLocation } from '../LocationForm/locationFormSlice'
import { ImEye } from 'react-icons/im'
import { RootState } from '../../store'
import { setUser } from '../UserForm/userFormSlice'
import UserForm from '../UserForm/UserForm'
import { setUserAction } from './usersSlice'

export default ({
    data,
    isUserFormVisible,
}: {
    data: any[]
    isUserFormVisible: boolean
}) => {
    const dispatch = useAppDispatch()
    const setUserForm = (data: any) => {
        dispatch(setUser(data))
    }
    const cols = [
        { label: 'id', field: 'userId' },
        { label: 'Name', field: 'displayName' },
        { label: 'Email', field: 'email' },
        { label: 'Phone', field: 'phone' },
        { label: 'Active', field: 'active' },
        {
            label: 'Actions',
            field: '',
            type: 'icons',
            icons: [
                {
                    show: true,
                    icon: <ImEye />,
                    callbackMethod: setUserForm,
                },
            ],
        },
    ]
    return (
        <>
            <Breadcrumbs />
            <Divider my={30} />
            <Flex my={10} justifyContent={'flex-end'}>
                <Button
                    colorScheme={'messenger'}
                    onClick={() =>
                        dispatch(
                            setUser({
                                role: null,
                                active: false,
                                location: '',
                                userId: '',
                                displayName: '',
                                email: '',
                                address: '',
                                phone: '',
                            })
                        )
                    }
                >
                    Create User
                </Button>
            </Flex>
            <CustomTable columns={cols} data={data} />
            {isUserFormVisible && <UserForm key={Date.now()} />}
        </>
    )
}
