import API from '../services/api'
import { IOrder, ORDER_STATUS } from '../containers/OrderForm/orderFormSlice'

export const getOrders = async (
    locationId: string,
    orderId: string = '',
    createdAt: number | string = '',
    currentDayOnly: boolean = true,
    orderType: string = 'IN_STORE'
) => {
    return await API.get(
        `/order?locationId=${locationId}&createdAt=${createdAt}&orderId=${orderId}&pageSize=20&currentDayOnly=${currentDayOnly}&orderType=${orderType}`
    )
}

export const setOrderStatus = async (
    id: string,
    orderStatus: ORDER_STATUS,
    readyInMinutes = 0
) => {
    return await API.put(`/order/${id}`, {
        orderStatus,
        ...(readyInMinutes && { readyInMinutes }),
    })
}

export const setOrderDiscount = async (id: string, discount: number) => {
    return await API.post(`/order/${id}/discount`, {
        discount,
    })
}

export const createOrder = async (data: any) => {
    return await API.post('/order', data)
}
export const createOrderAsAdmin = async (data: any) => {
    return await API.post('/order/approved', data)
}

export const getDailyStatisticsForLocation = async (
    locationId: string,
    startDate: number | string = '',
    endDate: number | string = '',
    searchQuery: string = '',
    hidden: boolean = false
) => {
    return await API.get(
        `/order/location/${locationId}/${startDate}/${endDate}?searchQuery=${searchQuery}&hidden=${hidden}`
    )
}

export const deleteOrder = async (id: string) => {
    return await API.delete(`/order/${id}`)
}
