import {
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    Text,
} from '@chakra-ui/react'
import React from 'react'

export default () => {
    const pathArray = window.location.pathname
        .split('/')
        .filter((pathItem) => !!pathItem)
    let oldPath = ''
    return (
        <Breadcrumb>
            {pathArray.map((pathItem) => {
                oldPath += `/${pathItem}`
                return (
                    <BreadcrumbItem key={pathItem}>
                        <BreadcrumbLink href={oldPath}>
                            <Text fontSize={'md'} casing={'capitalize'}>
                                {pathItem}
                            </Text>
                        </BreadcrumbLink>
                    </BreadcrumbItem>
                )
            })}
        </Breadcrumb>
    )
}
