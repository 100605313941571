import React, { useEffect, useRef, useState } from 'react'
import {
    Button,
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
    IconButton,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
    Tooltip,
    useDisclosure,
} from '@chakra-ui/react'
import { FiscalPrinter } from '../../services/fiscal-printer'
import { CheckCircleIcon, WarningIcon } from '@chakra-ui/icons'
import { FaBriefcase, FaBusinessTime } from 'react-icons/all'
export default () => {
    const fiscalPrinter = new FiscalPrinter()
    const [fiscalPrinterConnected, setFiscalPrinterConnected] = useState(false)
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [dayReportPassword, setDayReportPassword] = useState('')
    const [showPrintPasswordError, setShowPrintPasswordError] = useState(false)
    const dateNow = Date.now()
    const connectFiscalPrinter = async () => {
        await fiscalPrinter.connectPort()
        setFiscalPrinterConnected(true)
    }

    const effectRan = useRef(false)

    useEffect(() => {
        if (!effectRan.current) {
            fiscalPrinter
                .checkForPorts()
                .then((portAvailable) =>
                    setFiscalPrinterConnected(!!portAvailable)
                )
        }

        return () => {
            effectRan.current = true
            setShowPrintPasswordError(false)
            setDayReportPassword('')
        }
    }, [])

    const validateDayReportPassword = async () => {
        setShowPrintPasswordError(false)
        if (dayReportPassword !== '0000') {
            setShowPrintPasswordError(true)
        } else {
            await fiscalPrinter.printControlReport('1')
        }
    }

    return (
        <Flex flexDirection={'row'} alignItems={'center'}>
            <Text>
                Fiscal printer{' '}
                {fiscalPrinterConnected ? (
                    <CheckCircleIcon color={'green'} />
                ) : (
                    <WarningIcon
                        color={'red'}
                        cursor={'pointer'}
                        onClick={() => connectFiscalPrinter()}
                    />
                )}{' '}
            </Text>
            <Flex ml={10}>
                <Tooltip label={'Control report'}>
                    <IconButton
                        aria-label={'Control report'}
                        icon={<FaBriefcase />}
                        onClick={async () =>
                            await fiscalPrinter.printControlReport('2')
                        }
                        mr={5}
                    />
                </Tooltip>
                <Tooltip label={'Final day report'}>
                    <IconButton
                        aria-label={'Day report'}
                        icon={<FaBusinessTime />}
                        onClick={onOpen}
                    />
                </Tooltip>
            </Flex>

            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Daily report</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Text fontWeight="bold" mb="1rem">
                            Insert password to confirm printing of day report
                        </Text>
                        <FormControl isInvalid={showPrintPasswordError}>
                            <FormLabel>Password</FormLabel>
                            <Input
                                type={'password'}
                                onChange={(e) =>
                                    setDayReportPassword(e.target.value)
                                }
                            />
                            {showPrintPasswordError && (
                                <FormErrorMessage>
                                    Password is invalid!
                                </FormErrorMessage>
                            )}
                        </FormControl>
                    </ModalBody>

                    <ModalFooter>
                        <Button variant="ghost" mr={3} onClick={onClose}>
                            Close
                        </Button>
                        <Button
                            colorScheme="blue"
                            onClick={() => validateDayReportPassword()}
                        >
                            Print day report
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Flex>
    )
}
