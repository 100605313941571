import Axios from 'axios'
import { isAuth, logoutUser } from './auth'

const instance: any = Axios.create({
    baseURL: process.env.REACT_APP_API_URL || 'http://localhost:1337/',
})

instance.interceptors.request.use(
    (config: any) => {
        const token = isAuth()
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`
        }

        return config
    },
    (error: any) => Promise.reject(error)
)

instance.interceptors.response.use(
    (response: any) => response,
    (error: any) => {
        if ([401].includes(error.response.status)) {
            const isLoginReq =
                error.response.config.url.match(/auth/g) ||
                error.response.config.url.match(/api\/auth/g)

            if (!isLoginReq || !isLoginReq.length) {
                logoutUser()
                window.location.href = '/login'
            }
        }
        if ([403].includes(error.response.status)) {
            localStorage.setItem(
                'responseError',
                error.response.config.baseURL + error.response.config.url
            )
            window.location.hash = '#permission-error'
        }

        return Promise.reject(error)
    }
)

export default instance
