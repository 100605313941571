import React from 'react'
import {
    Button,
    Divider,
    Flex,
    Grid,
    Select,
    Spinner,
    Text,
} from '@chakra-ui/react'
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs'
import { useAppDispatch } from '../../hooks'
import { useNavigate } from 'react-router-dom'
import OrderCard from '../Orders/components/OrderCard/OrderCard'

interface IOrders {
    locations: any[]
    selectedLocation: string
    setSelectedLocation: Function
    orders: any[]
    loading: boolean
    handleDeleteOrder: Function
}
export default ({
    locations,
    selectedLocation,
    setSelectedLocation,
    loading,
    orders,
    handleDeleteOrder,
}: IOrders) => {
    return (
        <>
            <Breadcrumbs />
            <Divider my={10} />
            <Flex flexDirection={'column'} alignItems={'baseline'}>
                <Flex mb={10}>
                    <Select
                        value={selectedLocation}
                        onChange={(e) => {
                            setSelectedLocation(e.target.value)
                        }}
                    >
                        <option value={''}>None</option>
                        {locations.map((location) => (
                            <option value={location.locationId}>
                                {location.name}
                            </option>
                        ))}
                    </Select>
                </Flex>
                {loading && (
                    <Flex w={'full'}>
                        <Spinner size="xl" mx={'auto'} />
                    </Flex>
                )}
                <Flex flexWrap={'wrap'} gap={3}>
                    {orders.map((order: any) => (
                        <OrderCard
                            id={order.orderId}
                            shortId={order.shortOrderId}
                            status={order.status}
                            user={order.user}
                            items={order.items}
                            location={order.orderPlace}
                            createdAt={order.createdAt}
                            deleteOrder={() => handleDeleteOrder(order.orderId)}
                        />
                    ))}
                </Flex>
            </Flex>
        </>
    )
}
