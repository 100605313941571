import * as React from 'react'
import { Login } from '../containers/Login/Login'
import ForgotPassword from '../containers/ForgotPassword/ForgotPassword'
import ResetPassword from '../containers/ResetPassword/ResetPassword'
import { Navigate } from 'react-router-dom'

export const PublicRoutes = [
    {
        path: '/login',
        element: <Login />,
        index: true,
    },
    {
        path: '/reset-password',
        element: <ForgotPassword />,
    },
    {
        path: '/change-password/:token',
        element: <ResetPassword />,
    },
    {
        path: '/',
        element: <Navigate to={'/login'} />,
    },
    {
        path: '*',
        element: <Navigate to={'/login'} />,
    },
]
