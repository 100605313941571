import React from 'react'
import {
    IconButton,
    Table,
    TableCaption,
    TableContainer,
    Tbody,
    Td,
    Tfoot,
    Th,
    Thead,
    Tr,
} from '@chakra-ui/react'
import dayjs from 'dayjs'

interface ITable {
    caption?: string
    columns: {
        label: string
        field: string
        type?: string
        icons?: { show: boolean; icon: any; callbackMethod: Function }[]
    }[]
    data: any[]
}

function isValidDate(dateString: string) {
    const regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/
    return regex.test(dateString)
}

const formatCellValue = (value: any) => {
    if (value) {
        if (typeof value === 'boolean') return 'Yes'
        try {
            const dayJSDate = dayjs(value)
            if (isValidDate(value)) {
                return dayJSDate.format('DD/MM/YYYY - HH:mm')
            }
        } catch (e) {
            console.log(`Value ${value} is not date`)
        }
        return value
    }
    return '-'
}
export default (props: ITable) => {
    return (
        <TableContainer bg={'white'} borderRadius={'8px'}>
            <Table variant="simple">
                {!props.data?.length && <TableCaption>No results</TableCaption>}
                {!!props.caption && (
                    <TableCaption>{props.caption}</TableCaption>
                )}
                <Thead>
                    <Tr>
                        {props.columns?.map((col) => (
                            <Th key={Date.now() + Math.random()}>
                                {col.label}
                            </Th>
                        ))}
                    </Tr>
                </Thead>
                <Tbody>
                    {props.data.map((dataRow) => (
                        <Tr key={Date.now() + Math.random()}>
                            {props.columns?.map((col) =>
                                col.type !== 'icons' ? (
                                    <Td style={{ whiteSpace: 'pre-line' }}>
                                        {formatCellValue(dataRow[col.field])}
                                    </Td>
                                ) : (
                                    <Th>
                                        {col?.icons?.map((icon) => (
                                            <IconButton
                                                bg={'white'}
                                                borderRadius={'50%'}
                                                aria-label={Date.now().toString()}
                                                icon={icon.icon}
                                                onClick={() =>
                                                    icon.callbackMethod(dataRow)
                                                }
                                            />
                                        ))}
                                    </Th>
                                )
                            )}
                        </Tr>
                    ))}
                </Tbody>
            </Table>
        </TableContainer>
    )
}
