import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { getLocations } from '../../repository/locations'
import { deleteProduct, getProducts } from '../../repository/products'

interface IProductsSlice {
    isLoading: boolean
    data: any[]
    error: boolean
}

export const fetchAllProducts = createAsyncThunk(
    'products/fetchAllProducts',
    async (arg, thunkAPI) => {
        const response = await getProducts()
        return response.data.products
    }
)

export const deleteProductAction = createAsyncThunk(
    'products/deleteProduct',
    async (productId: string, thunkAPI) => {
        const response = await deleteProduct(productId)
        return response.data
    }
)

const initialState: IProductsSlice = {
    isLoading: false,
    data: [],
    error: false,
}
const productsSlice = createSlice({
    name: 'products',
    initialState,
    reducers: {
        setProductsAction: (state, action: PayloadAction<any[]>) => {
            state.data = action.payload
        },
    },
    extraReducers: (builder) =>
        builder
            .addCase(fetchAllProducts.pending, (state, action) => {
                state.isLoading = true
                state.error = false
            })
            .addCase(fetchAllProducts.fulfilled, (state, action) => {
                state.isLoading = false
                state.error = false
                state.data = action.payload
            })
            .addCase(deleteProductAction.pending, (state, action) => {
                state.isLoading = true
                state.error = false
            })
            .addCase(deleteProductAction.fulfilled, (state, action) => {
                state.isLoading = false
                state.error = false
            }),
})

export const { setProductsAction } = productsSlice.actions

export default productsSlice.reducer
