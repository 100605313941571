import { Flex } from '@chakra-ui/react'
import React from 'react'
import EatingLocationCard from '../EatingLocationCard/EatingLocationCard'

export default ({ handleClick }: { handleClick: Function }) => {
    return (
        <Flex
            justifyContent={'space-around'}
            flexWrap={'wrap'}
            flexDirection={'row'}
            gap={3}
        >
            <EatingLocationCard
                text={'Подигни од ресторанот'}
                iconPath={'/images-static/takeaway.png'}
                value={'TAKEAWAY'}
                handleClick={handleClick}
            />
            <EatingLocationCard
                text={'Јади во ресторанот'}
                iconPath={'/images-static/eat-in.png'}
                value={'EAT_IN'}
                handleClick={handleClick}
            />
        </Flex>
    )
}
