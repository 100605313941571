import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { login } from '../../repository/auth'
import { logoutUser, saveUserToken } from '../../services/account'
import {
    completePasswordReset,
    startResetPassword,
} from '../../repository/users'

export interface IResetPasswordSlice {
    isLoading: boolean
    success: boolean
    error: boolean
}

export const completeResetPasswordAction = createAsyncThunk(
    'resetPassword/completePasswordAction',
    async (
        {
            token,
            password,
            confirmPassword,
        }: { token: string; password: string; confirmPassword: string },
        thunkAPI
    ) => {
        const response = await completePasswordReset(
            token,
            password,
            confirmPassword
        )
        return response.data
    }
)

const initialState: IResetPasswordSlice = {
    isLoading: false,
    error: false,
    success: false,
}
const resetPasswordSlice = createSlice({
    name: 'resetPassword',
    initialState,
    reducers: {},
    extraReducers: (builder) =>
        builder
            .addCase(completeResetPasswordAction.pending, (state, action) => {
                state.isLoading = true
                state.error = false
                state.success = false
            })
            .addCase(completeResetPasswordAction.fulfilled, (state, action) => {
                state.isLoading = false
                state.error = false
                state.success = true
            })
            .addCase(completeResetPasswordAction.rejected, (state, action) => {
                state.isLoading = false
                state.error = true
                state.success = false
            }),
})

export default resetPasswordSlice.reducer
