import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { getUsers, IUser } from '../../repository/users'
import {
    deleteOrder,
    getDailyStatisticsForLocation,
} from '../../repository/orders'

interface IStatisticsSlice {
    isLoading: boolean
    data: {
        statistics: []
        orders: []
        totalRevenue: number
        discountedOrders: number
        discountedTotal: number
        fiscalOrders: number
        fiscalTotal: number
        nonFiscalOrders: number
        totalOrders: number
    }
    error: boolean
}

export const fetchDailyStatistics = createAsyncThunk(
    'statistics/fetchDailyStatistics',
    async (
        {
            locationId,
            startDate,
            endDate,
            searchQuery,
            hidden,
        }: {
            locationId: string
            startDate: string
            endDate: string
            searchQuery: string
            hidden: boolean
        },
        thunkAPI
    ) => {
        const response = await getDailyStatisticsForLocation(
            locationId,
            startDate,
            endDate,
            searchQuery,
            hidden
        )
        return response.data
    }
)

export const deleteOrderAction = createAsyncThunk(
    'statistics/deleteOrder',
    async (orderId: string, thunkAPI) => {
        const response = await deleteOrder(orderId)
        return response.data
    }
)

const initialState: IStatisticsSlice = {
    isLoading: false,
    data: {
        statistics: [],
        orders: [],
        totalRevenue: 0,
        discountedOrders: 0,
        discountedTotal: 0,
        fiscalTotal: 0,
        fiscalOrders: 0,
        nonFiscalOrders: 0,
        totalOrders: 0,
    },
    error: false,
}
const statisticsSlice = createSlice({
    name: 'statistics',
    initialState,
    reducers: {
        setDailyStatistics: (state, action: PayloadAction<any>) => {
            state.data = action.payload
        },
    },
    extraReducers: (builder) =>
        builder
            .addCase(deleteOrderAction.pending, (state, action) => {
                state.isLoading = true
                state.error = false
            })
            .addCase(deleteOrderAction.fulfilled, (state, action) => {
                state.isLoading = false
                state.error = false
            })
            .addCase(fetchDailyStatistics.pending, (state, action) => {
                state.isLoading = true
                state.error = false
            })
            .addCase(fetchDailyStatistics.fulfilled, (state, action) => {
                state.isLoading = false
                state.error = false
                state.data = {
                    discountedTotal: action.payload.discountedTotal,
                    discountedOrders: action.payload.discountedOrders,
                    totalRevenue: action.payload.totalRevenue,
                    fiscalOrders: action.payload.fiscalOrders,
                    fiscalTotal: action.payload.fiscalTotal,
                    nonFiscalOrders:
                        action.payload.orders?.orders.length -
                        action.payload.fiscalOrders,
                    statistics: action.payload.statistics,
                    totalOrders: action.payload.orders.orders.length,
                    orders: (action.payload.orders?.orders || []).map(
                        (order: any) => ({
                            ...order,
                            orderDiscount:
                                order.discount > 0
                                    ? (order.totalPrice * order.discount) / 100
                                    : 0,
                            orderItems: order.items
                                .map((item: any) => {
                                    const extras = item.extras.map(
                                        (extra: any) => extra.name
                                    )
                                    return (
                                        item.quantity +
                                        'x ' +
                                        item.item.name +
                                        ': ' +
                                        extras.join(',')
                                    )
                                })
                                .join('; \n'),
                        })
                    ),
                }
            }),
})

export const { setDailyStatistics } = statisticsSlice.actions

export default statisticsSlice.reducer
