import UsersView from './UsersView'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { RootState } from '../../store'
import { useEffect } from 'react'
import { fetchAllUsers } from './usersSlice'
import { fetchAllLocations } from '../Locations/locationsSlice'

export default () => {
    const dispatch = useAppDispatch()
    const users = useAppSelector((state: RootState) => state.users)
    const userForm = useAppSelector((state: RootState) => state.userForm)
    useEffect(() => {
        dispatch(fetchAllUsers())
        dispatch(fetchAllLocations())
    }, [])
    return (
        <UsersView data={users.data} isUserFormVisible={userForm.isVisible} />
    )
}
