import React from 'react'
import OrderCard from './components/OrderCard/OrderCard'
import {
    Button,
    Divider,
    Flex,
    Grid,
    Heading,
    Spinner,
    Text,
} from '@chakra-ui/react'
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs'
import { setOrder } from '../OrderForm/orderFormSlice'
import OrderForm from '../OrderForm/OrderForm'
import { useAppDispatch } from '../../hooks'
import { useNavigate } from 'react-router-dom'
import FiscalPrinterStatus from '../../components/FiscalPrinterStatus/FiscalPrinterStatus'

interface IOrders {
    isConnected: boolean
    orders: any[]
    isOrderFormVisible: boolean
    menu?: any[]
    loading: boolean
    handleHideOrder: Function
    rating: any
}
export default ({
    isConnected,
    orders,
    isOrderFormVisible,
    menu,
    loading,
    handleHideOrder,
    rating,
}: IOrders) => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    return (
        <>
            <Breadcrumbs />
            <Divider my={10} />
            {rating.currentRating && (
                <Heading size="sm">
                    Rating: {rating.currentRating}/5 of {rating.numberOfRatings}{' '}
                    users
                </Heading>
            )}
            {isConnected ? (
                <>
                    <Flex my={10} justifyContent={'space-between'}>
                        <FiscalPrinterStatus />
                    </Flex>
                    {loading && (
                        <Flex w={'full'}>
                            <Spinner size="xl" mx={'auto'} />
                        </Flex>
                    )}
                    <Flex
                        flexWrap={'wrap'}
                        gap={6}
                        p={5}
                        alignItems={'baseline'}
                    >
                        {orders.map((order) => (
                            <OrderCard
                                shortId={order.shortOrderId}
                                id={order.orderId}
                                status={order.orderStatus}
                                user={order.orderName || order.userId}
                                items={order.items}
                                phone={order.userEntity?.phone || ''}
                                comment={order.userEntity?.comment || ''}
                                menu={menu}
                                location={order.orderPlace}
                                createdAt={order.createdAt}
                                hideOrder={handleHideOrder}
                            />
                        ))}
                    </Flex>
                </>
            ) : (
                <Flex
                    direction={'column'}
                    justifyContent={'center'}
                    alignItems={'center'}
                >
                    <Text>Please wait while we connect to the server</Text>
                    {/*<Spinner*/}
                    {/*    thickness="4px"*/}
                    {/*    speed="0.65s"*/}
                    {/*    emptyColor="gray.200"*/}
                    {/*    color="blue.500"*/}
                    {/*    size="xl"*/}
                    {/*/>*/}
                </Flex>
            )}
        </>
    )
}
