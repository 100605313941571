import * as React from 'react'
import {
    ChakraProvider,
    Box,
    Text,
    Link,
    VStack,
    Code,
    Grid,
    Button,
    extendTheme,
} from '@chakra-ui/react'
import { ColorModeSwitcher } from './ColorModeSwitcher'
import { Logo } from './Logo'
import {
    BrowserRouter,
    createBrowserRouter,
    Route,
    RouterProvider,
    Routes,
} from 'react-router-dom'
import { useState } from 'react'
import { AdminRoutes } from './routes/AdminRoutes'
import { PublicRoutes } from './routes/PublicRoutes'
import AdminLayout from './layouts/AdminLayout'
import Splash from './layouts/Splash'
import { Provider } from 'react-redux'
import { store } from './store'
import { MultiSelectTheme } from 'chakra-multiselect'

const theme = extendTheme({
    components: {
        MultiSelect: MultiSelectTheme,
    },
})
export const App = () => {
    return (
        <ChakraProvider theme={theme}>
            <React.StrictMode>
                <Provider store={store}>
                    <BrowserRouter>
                        <Routes>
                            <Route
                                path={'/dashboard'}
                                element={<AdminLayout />}
                            >
                                {AdminRoutes.map((route) => (
                                    <Route
                                        path={route.path}
                                        element={route.element}
                                        key={route.path}
                                    />
                                ))}
                            </Route>
                            <Route path={''} element={<Splash />}>
                                {PublicRoutes.map((route) => (
                                    <Route
                                        path={route.path}
                                        element={route.element}
                                        key={route.path}
                                    />
                                ))}
                            </Route>
                            <Route
                                path={'*'}
                                element={<div>Not Found</div>}
                            ></Route>
                        </Routes>
                    </BrowserRouter>
                </Provider>
            </React.StrictMode>
        </ChakraProvider>
    )
}
