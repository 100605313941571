import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { login } from '../../repository/auth'
import { logoutUser, saveUserToken } from '../../services/account'
import { startResetPassword } from '../../repository/users'

export interface IForgotPasswordSlice {
    isLoading: boolean
    success: boolean
    error: boolean
}

export const startPasswordResetAction = createAsyncThunk(
    'forgotPassword/startForgotPasswordAction',
    async ({ email }: { email: string }, thunkAPI) => {
        const response = await startResetPassword(email)
        return response.data
    }
)

const initialState: IForgotPasswordSlice = {
    isLoading: false,
    error: false,
    success: false,
}
const forgotPasswordSlice = createSlice({
    name: 'forgotPassword',
    initialState,
    reducers: {},
    extraReducers: (builder) =>
        builder
            .addCase(startPasswordResetAction.pending, (state, action) => {
                state.isLoading = true
                state.error = false
                state.success = false
            })
            .addCase(startPasswordResetAction.fulfilled, (state, action) => {
                state.isLoading = false
                state.error = false
                state.success = true
            })
            .addCase(startPasswordResetAction.rejected, (state, action) => {
                state.isLoading = false
                state.error = true
                state.success = false
            }),
})

export default forgotPasswordSlice.reducer
