import DashboardView from './OnlineUsersView'
import { RootState } from '../../store'
import { useEffect, useState } from 'react'
import { setLocationsAction } from '../Locations/locationsSlice'
import OnlineUsers from './OnlineUsers'
import {
    addCommentForUser,
    fetchAppUsers,
    getUsers,
} from '../../repository/users'
import OnlineUsersView from './OnlineUsersView'

export default () => {
    const [users, setUsers] = useState([])
    useEffect(() => {
        fetchData()
    }, [])

    const fetchData = () => {
        fetchAppUsers()
            .then((data) => {
                setUsers(data.data.users)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const handleUserComment = (userPhone: string, comment: string) => {
        addCommentForUser(userPhone, comment)
            .then((data: any) => {
                fetchData()
            })
            .catch((err: any) => console.log(err))
    }
    return (
        <OnlineUsersView users={users} handleUserComment={handleUserComment} />
    )
}
