import AdminLayout from '../../layouts/AdminLayout'
import {
    Box,
    Button,
    Divider,
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
} from '@chakra-ui/react'
import React, { useState } from 'react'
import { IUser } from '../../repository/users'
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs'
import CustomTable from '../../components/CustomTable/CustomTable'
import { ImEye } from 'react-icons/im'
import { BiPencil } from 'react-icons/all'

export default ({
    users,
    handleUserComment,
}: {
    users: IUser[]
    handleUserComment: Function
}) => {
    const [itemToEdit, setItemToEdit] = useState(false as false | any)
    const [comment, setComment] = useState('')
    const cols = [
        { label: 'Phone', field: 'phone' },
        { label: 'Name', field: 'displayName' },
        { label: 'Comment', field: 'comment' },
        {
            label: 'Actions',
            field: '',
            type: 'icons',
            icons: [
                {
                    show: true,
                    icon: <BiPencil />,
                    callbackMethod: (data: any) => {
                        setItemToEdit(data.userId)
                    },
                },
            ],
        },
    ]
    return (
        <>
            <Breadcrumbs />
            <Divider my={30} />
            <Flex my={10} justifyContent={'flex-end'}></Flex>
            <CustomTable columns={cols} data={users} />
            <Modal
                blockScrollOnMount={false}
                isOpen={!!itemToEdit}
                onClose={() => setItemToEdit(false)}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>User comment</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Text fontWeight="bold" mb="1rem">
                            Insert comment for user
                        </Text>
                        <FormLabel>Comment</FormLabel>
                        <Input onChange={(e) => setComment(e.target.value)} />
                    </ModalBody>

                    <ModalFooter>
                        <Button mr={3} onClick={() => setItemToEdit(false)}>
                            Close
                        </Button>
                        <Button
                            colorScheme="blue"
                            onClick={() => {
                                handleUserComment(itemToEdit, comment)
                                setItemToEdit(false)
                            }}
                        >
                            Print
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}
