import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
    getOrders,
    setOrderDiscount,
    setOrderStatus,
} from '../../repository/orders'
import { ORDER_STATUS } from '../OrderForm/orderFormSlice'

interface IOrderSlice {
    isLoading: boolean
    data: any[]
    error: boolean
    reachedEnd: boolean
    lastKey: any
}

export const fetchAllOrders = createAsyncThunk(
    'orders/fetchAllOrders',
    async (
        {
            locationId,
            orderId,
            createdAt,
        }: {
            locationId: string
            orderId?: string
            createdAt?: number | string
        },
        thunkAPI
    ) => {
        const response = await getOrders(locationId, orderId, createdAt)
        return response.data
    }
)

export const acceptOrRejectOrder = createAsyncThunk(
    'orders/acceptOrRejectOrder',
    async ({
        orderId,
        status,
        callback,
    }: {
        orderId: string
        status: ORDER_STATUS
        callback: Function
    }): Promise<any> => {
        const response = await setOrderStatus(orderId, status)
        if (status === ORDER_STATUS.ACCEPTED) {
            callback()
        }
        return { orderId, orderStatus: response.data.orderStatus }
    }
)

export const hideOrderAction = createAsyncThunk(
    'orders/hideOrder',
    async ({ orderId }: { orderId: string }): Promise<any> => {
        const response = await setOrderStatus(orderId, ORDER_STATUS.HIDDEN)
        return { orderId, orderStatus: response.data.orderStatus }
    }
)

export const discountOrder = createAsyncThunk(
    'orders/discountOrder',
    async ({
        orderId,
        discount,
        callback,
    }: {
        orderId: string
        discount: number
        callback: Function
    }): Promise<any> => {
        const response = await setOrderDiscount(orderId, discount)
        return {
            orderId,
            orderStatus: response.data.orderStatus,
            discount: response.data.discount,
        }
    }
)

const initialState: IOrderSlice = {
    isLoading: false,
    reachedEnd: false,
    data: [],
    error: false,
    lastKey: {},
}
const ordersSlice = createSlice({
    name: 'orders',
    initialState,
    reducers: {
        addOrder: (state, action: PayloadAction<any>) => {
            state.data = [action.payload, ...state.data]
        },
        setOrdersAction: (state, action: PayloadAction<any[]>) => {
            state.data = action.payload
        },
        resetState: (state) => {
            state.data = []
            state.lastKey = {}
            state.isLoading = false
            state.error = false
            state.reachedEnd = false
        },
    },
    extraReducers: (builder) =>
        builder
            .addCase(fetchAllOrders.pending, (state, action) => {
                state.isLoading = true
                state.error = false
            })
            .addCase(fetchAllOrders.fulfilled, (state, action) => {
                state.isLoading = false
                state.error = false
                const orders = action.payload.orders
                state.data = [
                    ...state.data,
                    ...orders.map((order: any) => {
                        return {
                            ...order,
                            shortOrderId: order.orderId.split('-').pop(),
                        }
                    }),
                ]
                state.lastKey = action.payload?.lastKey || {}
                state.reachedEnd = !action.payload?.orders?.length
                console.log('Reached end', !action.payload?.orders?.length)
            })
            .addCase(acceptOrRejectOrder.pending, (state, action) => {
                state.isLoading = true
                state.error = false
            })
            .addCase(acceptOrRejectOrder.fulfilled, (state, action) => {
                state.isLoading = false
                state.error = false
                state.data.forEach((orderItem) => {
                    if (orderItem.orderId === action.payload.orderId) {
                        orderItem.orderStatus = action.payload.orderStatus
                    }
                })
            })
            .addCase(hideOrderAction.fulfilled, (state, action) => {
                state.data = state.data.filter(
                    (order: any) => order.orderId !== action.payload.orderId
                )
            })
            .addCase(discountOrder.fulfilled, (state, action) => {
                state.isLoading = false
                state.error = false
                state.data.forEach((orderItem) => {
                    if (orderItem.orderId === action.payload.orderId) {
                        orderItem.orderStatus = action.payload.orderStatus
                        orderItem.discount = action.payload.discount
                        console.log('Setting order')
                    }
                })
            }),
})

export const { setOrdersAction, resetState, addOrder } = ordersSlice.actions

export default ordersSlice.reducer
