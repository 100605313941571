import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { getUsers, IUser } from '../../repository/users'

interface IUsersSlice {
    isLoading: boolean
    data: IUser[]
    error: boolean
}

export const fetchAllUsers = createAsyncThunk(
    'locations/fetchAllUsers',
    async (arg, thunkAPI) => {
        const response = await getUsers()
        return response.data.users
    }
)

const initialState: IUsersSlice = {
    isLoading: false,
    data: [],
    error: false,
}
const usersSlice = createSlice({
    name: 'locations',
    initialState,
    reducers: {
        setUserAction: (state, action: PayloadAction<any>) => {
            state.data = action.payload
        },
    },
    extraReducers: (builder) =>
        builder
            .addCase(fetchAllUsers.pending, (state, action) => {
                state.isLoading = true
                state.error = false
            })
            .addCase(fetchAllUsers.fulfilled, (state, action) => {
                state.isLoading = false
                state.error = false
                state.data = action.payload
            }),
})

export const { setUserAction } = usersSlice.actions

export default usersSlice.reducer
