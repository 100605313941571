import React from 'react'
import Splash from '../../layouts/Splash'
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Heading,
    Input,
    Link,
    Spinner,
    Text,
} from '@chakra-ui/react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useAppDispatch } from '../../hooks'
import {
    IForgotPasswordSlice,
    startPasswordResetAction,
} from './forgotPasswordSlice'

type ForgotPasswordInputs = {
    email: string
}
export default ({ error, isLoading, success }: IForgotPasswordSlice) => {
    const dispatch = useAppDispatch()
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm<ForgotPasswordInputs>()
    const onSubmit: SubmitHandler<ForgotPasswordInputs> = (data) =>
        dispatch(startPasswordResetAction(data))
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Card size={'lg'}>
                <CardHeader>
                    <Heading as="h2" size="xl">
                        Please enter your email to reset the password.
                    </Heading>
                </CardHeader>
                {!isLoading ? (
                    success ? (
                        <Flex
                            p={5}
                            justifyContent={'center'}
                            flexDirection={'column'}
                        >
                            <Text textAlign={'center'}>
                                A password reset link has been generated and
                                sent to your email.
                            </Text>
                            <Link
                                href={'/login'}
                                color={'blue'}
                                mt={6}
                                alignSelf={'flex-end'}
                            >
                                Go back
                            </Link>
                        </Flex>
                    ) : (
                        <>
                            <CardBody>
                                <FormControl
                                    isInvalid={!!errors.email}
                                    mb={'30px'}
                                >
                                    <FormLabel>
                                        Email<span>*</span>
                                    </FormLabel>
                                    <Input
                                        type={'email'}
                                        {...register('email', {
                                            required: true,
                                        })}
                                    />
                                    {!!errors.email && (
                                        <FormErrorMessage>
                                            Email is required.
                                        </FormErrorMessage>
                                    )}
                                </FormControl>
                            </CardBody>
                            <CardFooter>
                                <Button
                                    type={'submit'}
                                    colorScheme="messenger"
                                    width={'100%'}
                                >
                                    Sign in
                                </Button>
                            </CardFooter>{' '}
                        </>
                    )
                ) : (
                    <Flex p={4}>
                        <Spinner
                            thickness="4px"
                            speed="0.65s"
                            emptyColor="gray.200"
                            color="blue.500"
                            size="xl"
                            mx={'auto'}
                        />
                    </Flex>
                )}
            </Card>
        </form>
    )
}
