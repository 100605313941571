import ResetPasswordView from './ResetPasswordView'
import { useParams } from 'react-router-dom'
import { useAppSelector } from '../../hooks'
import { RootState } from '../../store'

export default () => {
    let { token } = useParams()
    const { error, isLoading, success } = useAppSelector(
        (state: RootState) => state.resetPassword
    )
    return (
        <ResetPasswordView
            token={token || ''}
            error={error}
            success={success}
            isLoading={isLoading}
        />
    )
}
