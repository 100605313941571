import OrdersView from './OrdersView'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { RootState } from '../../store'
import React, { useEffect, useState } from 'react'
import {
    addOrder,
    fetchAllOrders,
    hideOrderAction,
    resetState,
    setOrdersAction,
} from './orderSlice'
import { fetchAllLocations } from '../Locations/locationsSlice'
import { useParams } from 'react-router-dom'
import { useInView } from 'react-intersection-observer'
import { getLocalAccount } from '../../services/account'
import { Flex, Spinner } from '@chakra-ui/react'
import { Simulate } from 'react-dom/test-utils'

export default () => {
    const dispatch = useAppDispatch()
    const orders = useAppSelector((state: RootState) => state.orders)
    const orderForm = useAppSelector((state: RootState) => state.orderForm)
    const locations = useAppSelector((state: RootState) => state.locations)
    const account = getLocalAccount()
    const [fetchingData, setFetchingData] = useState(true)
    const { ref, inView, entry } = useInView({
        threshold: 0,
        delay: 0.5,
    })
    const dataFetcher = async () => {
        await dispatch(fetchAllLocations()).unwrap()
    }

    const paginateOrders = async () => {
        setFetchingData(true)

        await dispatch(
            fetchAllOrders({
                locationId: account.location,
                orderId: orders.lastKey.orderId,
                createdAt: orders.lastKey.createdAt,
            })
        ).unwrap()
        setFetchingData(false)
    }
    useEffect(() => {
        dataFetcher()
        paginateOrders()
        // const fetchOrdersInterval = setInterval(() => {
        //     dispatch(fetchAllOrders())
        // }, 10000)
        // return () => {
        //     clearInterval(fetchOrdersInterval)
        // }
        return () => {
            dispatch(resetState())
        }
    }, [])

    useEffect(() => {
        if (inView && !fetchingData && !orders.reachedEnd) {
            paginateOrders()
        }
    }, [inView])

    const handleHideOrder = (orderId: string) => {
        dispatch(hideOrderAction({ orderId }))
    }
    return (
        <>
            <OrdersView
                isConnected={true}
                orders={orders.data}
                isOrderFormVisible={orderForm.isVisible}
                menu={locations.selectedLocation?.menu || []}
                loading={orders.isLoading}
                handleHideOrder={handleHideOrder}
            />
            {!orders.reachedEnd && (
                <Flex
                    alignItems={'center'}
                    justifyContent={'center'}
                    w="full"
                    ref={ref}
                >
                    <Flex w={'full'}>
                        <Spinner size="xl" mx={'auto'} />
                    </Flex>
                </Flex>
            )}
        </>
    )
}
