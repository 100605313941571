import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
    getOrders,
    setOrderDiscount,
    setOrderStatus,
} from '../../repository/orders'
import { ORDER_STATUS } from '../OrderForm/orderFormSlice'

interface IOrderSlice {
    isLoading: boolean
    data: any[]
    error: boolean
    reachedEnd: boolean
    lastKey: any
}

export const fetchAllOnlineOrders = createAsyncThunk(
    'onlineOrders/fetchAllOrders',
    async (
        {
            locationId,
            orderId,
            createdAt,
            orderType,
        }: {
            locationId: string
            orderId?: string
            createdAt?: number | string
            orderType?: string
        },
        thunkAPI
    ) => {
        const response = await getOrders(
            locationId,
            orderId,
            createdAt,
            true,
            orderType
        )
        return response.data
    }
)

export const acceptOrRejectOnlineOrder = createAsyncThunk(
    'onlineOrders/acceptOrRejectOrder',
    async ({
        orderId,
        status,
        readyInMinutes,
    }: {
        orderId: string
        status: ORDER_STATUS
        readyInMinutes: number
    }): Promise<any> => {
        const response = await setOrderStatus(orderId, status, readyInMinutes)
        return {
            orderId,
            orderStatus: response.data.orderStatus,
            readyInMinutes,
        }
    }
)

export const hideOnlineOrderAction = createAsyncThunk(
    'onlineOrders/hideOrder',
    async ({ orderId }: { orderId: string }): Promise<any> => {
        const response = await setOrderStatus(orderId, ORDER_STATUS.HIDDEN)
        return { orderId, orderStatus: response.data.orderStatus }
    }
)

export const discountOnlineOrder = createAsyncThunk(
    'onlineOrders/discountOrder',
    async ({
        orderId,
        discount,
        callback,
    }: {
        orderId: string
        discount: number
        callback: Function
    }): Promise<any> => {
        const response = await setOrderDiscount(orderId, discount)
        return {
            orderId,
            orderStatus: response.data.orderStatus,
            discount: response.data.discount,
        }
    }
)

const initialState: IOrderSlice = {
    isLoading: false,
    reachedEnd: false,
    data: [],
    error: false,
    lastKey: {},
}
const onlineOrdersSlice = createSlice({
    name: 'onlineOrders',
    initialState,
    reducers: {
        addOnlineOrder: (state, action: PayloadAction<any>) => {
            state.data = [action.payload, ...state.data]
        },
        setOnlineOrdersAction: (state, action: PayloadAction<any[]>) => {
            state.data = action.payload
        },
        resetOnlineState: (state) => {
            state.data = []
            state.lastKey = {}
            state.isLoading = false
            state.error = false
            state.reachedEnd = false
        },
    },
    extraReducers: (builder) =>
        builder
            .addCase(fetchAllOnlineOrders.pending, (state, action) => {
                state.isLoading = true
                state.error = false
            })
            .addCase(fetchAllOnlineOrders.fulfilled, (state, action) => {
                state.isLoading = false
                state.error = false
                const orders = action.payload.orders
                state.data = [
                    ...state.data,
                    ...orders.map((order: any) => {
                        return {
                            ...order,
                            shortOrderId: order.orderId.split('-').pop(),
                        }
                    }),
                ]
                state.lastKey = action.payload?.lastKey || {}
                state.reachedEnd = !action.payload?.orders?.length
                console.log('Reached end', !action.payload?.orders?.length)
            })
            .addCase(acceptOrRejectOnlineOrder.pending, (state, action) => {
                state.isLoading = true
                state.error = false
            })
            .addCase(acceptOrRejectOnlineOrder.fulfilled, (state, action) => {
                state.isLoading = false
                state.error = false
                state.data.forEach((orderItem) => {
                    if (orderItem.orderId === action.payload.orderId) {
                        orderItem.orderStatus = action.payload.orderStatus
                    }
                })
            })
            .addCase(hideOnlineOrderAction.fulfilled, (state, action) => {
                state.data = state.data.filter(
                    (order: any) => order.orderId !== action.payload.orderId
                )
            })
            .addCase(discountOnlineOrder.fulfilled, (state, action) => {
                state.isLoading = false
                state.error = false
                state.data.forEach((orderItem) => {
                    if (orderItem.orderId === action.payload.orderId) {
                        orderItem.orderStatus = action.payload.orderStatus
                        orderItem.discount = action.payload.discount
                        console.log('Setting order')
                    }
                })
            }),
})

export const { setOnlineOrdersAction, resetOnlineState, addOnlineOrder } =
    onlineOrdersSlice.actions

export default onlineOrdersSlice.reducer
