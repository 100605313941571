import { SubmitHandler, useFormContext } from 'react-hook-form'
import { createOrder } from '../../../../repository/orders'
import { useAppDispatch } from '../../../../hooks'
import {
    Button,
    Flex,
    FormControl,
    FormErrorMessage,
    FormHelperText,
    FormLabel,
    Input,
    useToast,
} from '@chakra-ui/react'
import { useNavigate, useParams } from 'react-router-dom'
import { createNewOrder } from '../../orderFormSlice'

export default () => {
    const {
        register,
        formState: { errors },
    } = useFormContext()
    const navigate = useNavigate()
    const handleCancelOrder = () => navigate('/dashboard/orders')

    return (
        <Flex direction={'column'}>
            <FormControl isInvalid={!!errors.orderName}>
                <FormLabel>Име:</FormLabel>
                <Input {...register('orderName', { required: true })} />
                {!!errors.orderName && (
                    <FormErrorMessage>Името е задолжително</FormErrorMessage>
                )}
            </FormControl>
            <FormControl isInvalid={!!errors.phoneNumber}>
                <FormLabel>Телефонски број за контакт:</FormLabel>
                <Input
                    {...register('phoneNumber', {
                        pattern: {
                            value: /^\+\d{11}$/,
                            message: 'Грешен формат на телефонски број',
                        },
                    })}
                />
                <FormHelperText>
                    Форматот мора да биде: +38911222333
                </FormHelperText>
                {!!errors.phoneNumber && (
                    <FormErrorMessage>
                        {errors?.phoneNumber?.message?.toString() || ''}
                    </FormErrorMessage>
                )}
            </FormControl>

            <Button colorScheme={'messenger'} type={'submit'} mt={5}>
                Креирај нарачка
            </Button>
            <Button mt={5} onClick={() => handleCancelOrder()}>
                Почни одново
            </Button>
        </Flex>
    )
}
