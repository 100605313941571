import MenuPageView from './MenuPageView'
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../hooks'
import { RootState } from '../../../../store'
import { setSelectedItem } from '../../orderFormSlice'
import { useFieldArray, useFormContext } from 'react-hook-form'

export enum ProductType {
    FOOD = 'FOOD',
    EXTRAS = 'EXTRAS',
    DRINK = 'DRINK',
    MEAT = 'MEAT',
    SAUCE = 'SAUCE',
    COMBO = 'COMBO',
    DESSERT = 'DESSERT',
    SEASONAL = 'SEASONAL',
    SIDE = 'SIDE',
}
export default ({ setOrderStep }: { setOrderStep: Function }) => {
    const form = useFormContext()
    const dispatch = useAppDispatch()
    const locationsState = useAppSelector((state: RootState) => state.locations)
    const orderForm = useAppSelector((state: RootState) => state.orderForm)
    const navigate = useNavigate()
    const { fields, append, prepend, remove, update } = useFieldArray({
        control: form.control,
        name: 'order',
    })
    const handleSelectItem = (item: any) => {
        if (item) {
            prepend({
                product: item.productId,
                quantity: 1,
                extras: [],
            })
        }

        dispatch(setSelectedItem(item))
    }
    const changeItemQuantity = (itemIndex: number, newQuantity: number) => {
        const itemValue = form.getValues(`order`)
        if (itemValue[itemIndex].quantity + newQuantity < 1) {
            remove(itemIndex)
        } else {
            update(itemIndex, {
                ...itemValue[itemIndex],
                quantity: itemValue[itemIndex].quantity + newQuantity,
            })
        }
    }
    const handleCancelItem = (item: any) => {
        remove(0)
        dispatch(setSelectedItem(null))
    }

    const handleConfirmOrder = () => {
        setOrderStep(2)
    }

    const handleCancelOrder = () => {
        navigate(`/dashboard/orders`)
    }

    const filterProductsByType = (products: any[], type: string) => {
        return products.filter((product) => product.productType === type)
    }

    return (
        <MenuPageView
            food={filterProductsByType(
                locationsState.selectedLocation?.menu || [],
                ProductType.FOOD
            )}
            drink={filterProductsByType(
                locationsState.selectedLocation?.menu || [],
                ProductType.DRINK
            )}
            meat={filterProductsByType(
                locationsState.selectedLocation?.menu || [],
                ProductType.MEAT
            )}
            sauce={filterProductsByType(
                locationsState.selectedLocation?.menu || [],
                ProductType.SAUCE
            )}
            topping={filterProductsByType(
                locationsState.selectedLocation?.menu || [],
                ProductType.EXTRAS
            )}
            dessert={filterProductsByType(
                locationsState.selectedLocation?.menu || [],
                ProductType.DESSERT
            )}
            combo={filterProductsByType(
                locationsState.selectedLocation?.menu || [],
                ProductType.COMBO
            )}
            seasonal={filterProductsByType(
                locationsState.selectedLocation?.menu || [],
                ProductType.SEASONAL
            )}
            sides={filterProductsByType(
                locationsState.selectedLocation?.menu || [],
                ProductType.SIDE
            )}
            selectItem={handleSelectItem}
            selectedItem={orderForm.selectedProduct}
            clearItem={handleCancelItem}
            changeItemQuantity={changeItemQuantity}
            handleConfirmOrder={handleConfirmOrder}
            handleCancelOrder={handleCancelOrder}
        />
    )
}
