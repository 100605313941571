import React from 'react'
import ForgotPasswordView from './ForgotPasswordView'
import { useAppSelector } from '../../hooks'
import { RootState } from '../../store'

export default () => {
    const { error, isLoading, success } = useAppSelector(
        (state: RootState) => state.forgotPassword
    )
    return (
        <ForgotPasswordView
            error={error}
            isLoading={isLoading}
            success={success}
        />
    )
}
